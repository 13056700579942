/*===================
 		COMMUN
 ==================*/
 .pi-no-padding{padding:0!important}
 li.navItem.visible-980 {display: none;}
.visible-640{display:none}
.margin-10{margin:0 -10px}
.image-mobile{display:none}
@media only screen and (max-width:1500px) {
.header-sticky{padding:0 10px}
}

@media only screen and (max-width:1250px) {
.big-wrapper{  overflow-x: hidden;overflow-y: auto;width: 100%;}

.absolute-md {position: absolute;right: 5px;top: 50%;padding: 0;transform: translate(0, -50%);-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);-spec-transform: translate(0, -50%);width: auto;}
.container {max-width: 100%;}
.other-link-top li{padding-left: 10px; padding-right: 10px;}
}
@media only screen and (max-width:1200px) {

.other-link-top{max-width: 240px;}
.mobile-link em{font-size:12px;}
ul.onglets>li.navItem> .navLink{padding-right: 10px; padding-left: 10px;}
}

@media only screen and (max-width:1023px) {
.hidden-on-lg{display: none!important;}
.add-paniersuccess .panier-deroulant{display:none!important; opacity:0!important}
a.ctc-link.link-other{font-size: 0;padding-left: 0;width: 30px;background-position: 50% 2px!important;}
a.ctc-link.link-other:hover {background-position: 50% -24px;}
.other-link-top{max-width: 145px;}
}
@media only screen and (min-width:993px) {
.menu-mob-overlay{display:none!important}
}
.other-lien-menu{display:none}

@media only screen and (max-width:991px) {
	.panier-deroulant{display: none!important;}
	.contact-header-l{display:none!important}
	.li-m980{display:block}
.big-wrapper {padding-bottom: 0;}

.no-scrolling{overflow:hidden!important}
		.logo-site {margin-top: -5px;margin-bottom: -5px;}
.menu-mobile-bloc{display:block}
.left-logo {text-align:center;}
	.logo-site img {margin: auto;max-width: 300px;}
	.logo-site a{padding-bottom: 0}
		.slogan{font-size: 12px}

.mobile-link em{display: none;}

.other-link-top a.link-other{position:relative}
.hidden-mobile{display:none}
.visible-mobile{display:block}
/********Menu******/
.navigation-site {display: block;position: fixed;left: 0;background-color: #000;left: -500%;top: 0;height: 100%;z-index: 999;width: 75%;padding-top: 38px;transition: all ease 0.7s;-moz-transition: all ease 0.7s;-webkit-transition: all ease 0.7s;-ms-transition: all ease 0.7s;-o-transition: all ease 0.7s;max-width:270px}
.navigation-site >.container {height: 100%;background-color: #ffffff;overflow-x: hidden;overflow-y: auto;position: relative!important;padding-left: 15px!important;padding-right: 15px!important;padding-top: 5px;padding-bottom: 5px;}
	.navigation-site .mCSB_inside>.mCSB_container {margin-right: 15px!important;}
.navigation-site.open-mob {left: 0;transition: all ease 0.7s;-moz-transition: all ease 0.7s;-webkit-transition: all ease 0.7s;-ms-transition: all ease 0.7s;-o-transition: all ease 0.7s;}

		ul.onglets {display: block;border-top:1px solid #fff; max-width:100%}
		.close-menu-mob{display:block}

		ul.onglets>.has-sub >.navLink::after {content: '\e8ca';position: absolute;right:5px;left:auto;font-family:'fontello';top: 50%;font-size: 15px;transition: all ease 0.5s;-webkit-transition: all ease 0.5s;-ms-transition: all ease 0.5s;-o-transition: all ease 0.5s;-moz-transition: all ease 0.5s;background:none; display:block!important; transform:translate(0,-50%); -webkit-transform:translate(0,-50%); -ms-transform:translate(0,-50%);-spec-transform: translate(0, -50%);}
		ul.onglets>.active> .navLink::after {content:'\e8cd';transition: all ease 0.5s;
-webkit-transition: all ease 0.5s;-ms-transition: all ease 0.5s;-o-transition: all ease 0.5s;-moz-transition: all ease 0.5s;}
ul.onglets>li.navItem{display:block;padding:0}
ul.onglets>li.navItem.visible-980 {display: block;}
ul.onglets>li.navItem>a.navLink{color:#000000; text-align:left; padding:14px 10px;border-bottom: 1px solid #b3b3b3; font-size:15px; max-width: 100% ;border-top:none!important; border-left:none!important;border-right:none!important;}

	.hidden-980{display:none}

.hidden-logo{display:inline-block}
.visible-logo{display:none}
.no-scrolling .link-mobile-fixed{display:none}
	.hidden-on-lg{display: none!important;}
	.right-bloc-head{position: absolute;top: 50%;right: 0;transform: translate(0, -50%);-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);-spec-transform: translate(0, -50%);padding-top: 0;z-index: 99;width: auto;padding: 0}
		.other-link-top li{padding: 0 8px; }
		.other-link-top{max-width: 140px}
}

@media only screen and (max-width:767px) {

.hidden-on-md{display: none!important;}
.link-mobile-fixed {display: block;}
.hidden-767{display:none}
.goToTop{width: 49px;padding: 4px 10px;bottom: 65px;}
.goToTop.change-position{bottom: 105px;}
.margin-10.visible-980 {display: none !important;}
.box-flex.bloc-ong-categ .sub-product li {width: 100%;}
.rech-mobile {position: relative;margin-top:8px}
.mobile-link em{font-size:11px;}
.link-mobile-fixed a img{max-width: 40px;}
button#cookie_consent_use_only_functional_cookies {position: initial; margin: auto;}
}

@media only screen and (max-width:600px) {

.menu-mobile-bloc span{font-size:10px ; display: none;}
.menu-mobile{padding-right:10px}
.texte-promo{font-size:12px}

.other-link-top .lib-other{font-size:11px}
.toggle-menu {max-width: 38px;display: block;}

.hidden-640 {display:none!important}

.logo {margin-top:0;padding:0;margin-left: -10px;}
	.logo-site img {max-width:250px;}
	.logo-site a{padding-bottom: 0}
.visible-640{display:block}
	.panier-link {background-size: 22px;}

.menu-mobile-bloc {display: block;position: absolute;left: 0;top:50%;z-index:9;width: 50px;padding-left:0;transform: translate(0, -50%);-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);-spec-transform: translate(0, -50%);padding-top: 0}
.other-link-top .link-other{font-size:12px;font-weight: 700}
}

@media only screen and (max-width:575px) {
}
@media only screen and (max-width:480px) {
		.link-mobile-fixed a img{max-width: 35px; }

		.logo-site{margin-left:0; text-align: left;padding-left: 50px;}
		.logo-site img {max-width:217px;}
		.top-header{font-size: 13px}
		.link-top-ul >li {padding: 0 2px;}
		.link-top-ul >li>a.link-top-language{padding-right: 20px;}
}
@media only screen and (max-width:390px) {
	.logo-site img {max-width:200px;}
	.menu-mobile-bloc{width: 40px;}
.logo-site{padding-left: 42px;}
}

@media only screen and (max-width:370px) {
	.logo-site img {max-width:180px;}
	.other-link-top li {padding: 0 5px}

}
@media only screen and (max-width:340px) {
	a.compte-link.link-other{height: 22px;background-size: auto 48px;width: 25px;}
	a.compte-link.link-other:hover {background-position: 50% -28px;}
	a.panier-link.link-other{height: 20px;background-size: auto 40px;width: 25px;}
	a.panier-link.link-other:hover {background-position: 50% -20px;}
}
/*===================
 	SLIDER
 ==================*/
 .btn-mobile-slide {display: none;}
@media only screen and (max-width:1280px) {
	.bb-other-step .titre-perso-medium {font-size: 38px;}

}
@media only screen and (max-width:1180px) {
	.bb-other-step .titre-perso-medium {font-size: 35px;}

}
@media only screen and (max-width:991px) {
.home-next {height: 50px;background-size:20px 100px;margin-top: -25px;right: 0;}
.home-prev {height: 50px;background-size:20px 100px;margin-top: -25px;left: 0;}
.bb-other-step .titre-perso-medium {font-size: 32px;}

	.content-flex {padding: 0;width: 100%;}
	.texte-ccm{left: 0%;}
	.bb-other-step{padding-right: 15px;padding-left: 15px;}
}
@media only screen and (max-width:767px) {
	.bb-other-step{background-size: cover;padding-right: 10px;padding-left: 10px;}
	.step-slider-content {max-width: 400px;margin: auto; }

.titre-stepbox-slider {display: inline-block;margin-bottom: 0;padding-left: 10px;padding-top: 0;vertical-align: bottom;}
.stepbox-slider {text-align: left;margin-bottom: 20px;font-size: 14px;}
.texte-stepbox-slider {padding-top: 5px;padding-left: 10px;}
}
@media only screen and (max-width:575px) {
	.home-prev,.home-next {display: none;}
	.bb-other-step .titre-perso-medium span{font-size: 18px;}
}

@media only screen and (max-width:480px) {
	.conteneur-g-slider{background: none!important;}
	.bb-other-step{background-image: url('../images/bg_slider_mob.jpg');background-color: #132D35;}
	.bb-other-step .bg-vert-s.btn-link{min-width: 260px;}
	.stepbox-slider{padding-left: 5px;}
}
@media only screen and (max-width:380px) {
	.bb-other-step .titre-perso-medium {font-size: 30px;}
	.bb-other-step .titre-perso-medium span{font-size: 16px;}
		.stepbox-slider {font-size: 13px;}
		.titre-stepbox-slider{font-size: 19px;}
}
@media only screen and (max-width:340px) {
	.bb-other-step .titre-perso-medium {font-size: 26px;}
	.bb-other-step .titre-perso-medium span{font-size: 15px;}
	.stepbox-slider {font-size: 12px;}
	.titre-stepbox-slider{font-size: 18px;}
	.bb-other-step .bg-vert-s.btn-link{padding-top: 20px; padding-bottom: 20px; font-size: 13px;}
}

/*===============================
		ABOUT US
================================*/
@media only screen and (max-width:1200px) {

	.titre-perso-medium{font-size: 24px}
		.titre-perso-medium strong{font-size: 36px}
	.titre-light-big{font-size: 38px}
	.titre-light-verybig{font-size: 45px}

	.reseau-bloc h3.titre-light-big	{font-size: 35px;}
.reseau-bloc h3.titre-light-big strong{font-size: 25px;}

}
@media only screen and (max-width:1080px) {
.img-about img{max-width: 75px}
.max-container-1{max-width: 100%; padding-left: 15px; padding-right: 15px}
.titre-light-verybig{font-size: 40px}
}
@media only screen and (max-width:991px) {

.titre-perso-medium strong{font-size: 34px}
.btn-link{min-width:150px}
.sofiaregular-16{font-size: 14px}

.text-regular-onlg{font-family: "Sofia Pro"}
	.titre-perso-medium{font-size: 22px}
	.titre-light-big{font-size: 35px}
		.reseau-bloc h3.titre-light-big	{font-size: 30px;letter-spacing: 4px;}
		.reseau-bloc h3.titre-light-big strong{font-size: 20px;letter-spacing: 4px;}
		.titre-light-verybig{font-size: 38px}
}
@media only screen and (max-width:767px) {
.titre-medium{font-size: 32px}
.img-about img{max-width: 75px}
.fontsize-mobile-text{font-size: 14px}
.text-gris-onlg{color: #4D4D4D;}
.margin-n1{margin-right: -15px; margin-left: -15px}
.hover-img-scale{font-size: 16px}
.sofiaregular-16{font-size: 16px}
	.titre-perso-medium{font-size: 20px}
.d-none-mobile{display: none;}
.titre-light-big{font-size: 32px}
		.reseau-bloc h3.titre-light-big	{font-size: 25px;}
.reseau-bloc h3.titre-light-big strong{font-size: 17px;}
.titre-light-verybig{font-size: 34px}

}
@media only screen and (max-width:575px) {
	.btn-link {min-width: 140px;}
.titre-perso-medium strong{font-size: 32px}
		.reseau-bloc h3.titre-light-big	{font-size: 23px;}
.reseau-bloc h3.titre-light-big strong{font-size: 15px;}
.titre-light-verybig{font-size: 32px}
}
@media only screen and (max-width:480px) {
	.titre-perso-medium{font-size: 19px}
.titre-perso-medium strong{font-size: 30px}
.titre-light-verybig{font-size: 27px}
}

@media only screen and (max-width:380px) {
.titre-medium{font-size: 28px}
.fontsize-mobile-text{font-size: 13px}
.fontsize-mobilesm-text{font-size: 15px!important}
.max-container-1{padding-right: 0.5rem; padding-left:0.5rem}
.margin-n1{margin-right: -0.5rem; margin-left: -0.5rem}
.btn-link{min-width: 130px;font-size: 13px;padding-left: 10px;padding-right: 10px;}
.hover-img-scale{font-size: 15px}
.sofiaregular-16{font-size: 15px}
	.titre-perso-medium{font-size: 18px}
.titre-perso-medium strong{font-size:28px}
		.reseau-bloc h3.titre-light-big	{font-size: 20px;}
.reseau-bloc h3.titre-light-big strong{font-size: 12px;}
.titre-light-verybig{font-size: 23px}
}

@media only screen and (max-width:340px) {
.titre-medium{font-size: 22px}

.hover-img-scale{font-size: 14px}
.sofiaregular-16{font-size: 14px}
.btn-link{min-width: 110px;padding-left: 5px;padding-right: 5px;font-size: 12px;}
	.titre-perso-medium{font-size: 16px}
.titre-perso-medium strong{font-size:26px}
		.reseau-bloc h3.titre-light-big	{font-size: 18px;}
.reseau-bloc h3.titre-light-big strong{font-size: 11px;}
.titre-light-verybig{font-size: 21px}

}
/*===============================
		COLLECTION INDEX
================================*/
@media only screen and (max-width:767px) {
	.bloc-collection-index iframe {height: 300px;}
}
@media only screen and (max-width:480px) {
	.bloc-collection-index .bg-vert-opac {background: #E1EAE1;}
	.bloc-collection-index iframe {height: 250px;}
}

@media only screen and (max-width:370px) {
	.bloc-collection-index .pl-lg-5.pl-md-4.px-md-3.px-4.pt-4.pb-sm-4.pb-4{padding-left: 10px!important;padding-right: 10px!important;}
}

/*===============================
		REALISATIONS INDEX
================================*/

@media only screen and (max-width:991px) {
.quote-design{font-size: 16px;}
}
@media only screen and (max-width:767px) {
.quote-design{font-size: 15px;}
.quote-design q::before{font-size: 85px;}
.quote-design q::after{font-size: 85px;}

}
@media only screen and (max-width:575px) {
.quote-design{font-size: 14px;padding-left: 55px;padding-right: 55px;padding-bottom: 20px; padding-top: 20px;}
.quote-design p.mb-0-sm{margin-bottom: 5px;}
.quote-design q::before{font-size: 75px;}
.quote-design q::after{font-size: 75px;}
.bloc-inspiration{background-image: none!important;}
}
@media only screen and (max-width:370px) {
.quote-design{font-size: 13px;padding-left: 45px;padding-right: 45px;}
.quote-design q::before{font-size: 70px;}
.quote-design q::after{font-size: 70px;}
.texte-perso.text-regular.px-sm-2.px-3 {padding-top: 2rem!important;padding-bottom: 2rem!important;}
blockquote.blockquote.mb-0-sm {margin-bottom: 0;}
}
@media only screen and (max-width:340px) {
.quote-design{font-size: 11px;padding-left: 30px;padding-right: 30px;}
.quote-design q::before{font-size: 60px;}
.quote-design q::after{font-size: 60px;}
.texte-perso.text-regular.px-sm-2.px-3 {padding-left: 10px!important;padding-right: 10px!important;padding-top: 1.5rem!important;padding-bottom: 1.5rem!important;}
}
/*===============================
		SELECTION INDEX
================================*/
@media only screen and (max-width:1200px) {
.produit-selectnouv-wrapper {margin: 0 -18px;}
.produit-selectnouv-item{padding: 0 20px;margin-bottom: 25px;}
.produit-selectnouv-item:first-child .articleBit-ima {margin-bottom: 9%;}
.produit-selectnouv-item:first-child{margin-bottom: 11px;}
}

@media only screen and (max-width:991px) {
.produit-selectnouv-wrapper {margin: 0 -8px;}
.produit-selectnouv-item{padding: 0 10px;margin-bottom: 20px;}
.produit-selectnouv-item:first-child .articleBit-ima {margin-bottom: 15%;}
.produit-selectnouv-item:nth-child(n+6){display: none!important;}
}

@media only screen and (max-width:850px) {
.produit-selectnouv-item:first-child .articleBit-ima {margin-bottom:18%;}
}
@media only screen and (max-width:767px) {

	.minw-big-mobile{min-width: 240px}
	.produit-selectnouv-wrapper {margin: 0 -2px;}
.produit-selectnouv-item{padding: 0 5px;margin-bottom: 10px;}
.produit-selectnouv-item:first-child .articleBit-ima {margin-bottom: 22%;}
}
@media only screen and (max-width:680px) {
	.produit-selectnouv-item:first-child .articleBit-ima {margin-bottom: 25%;}
}
@media only screen and (max-width:640px) {
	.produit-selectnouv-item:first-child{margin-bottom: 10px;width: 100%!important;}
	.produit-selectnouv-item:first-child h3.articleBit-lib{height: auto;min-height: 20px;}
	.produit-selectnouv-item:first-child h3.articleBit-lib a{height: auto;}
	.produit-selectnouv-item{width: 50%!important;padding: 0 10px;margin-bottom: 15px;}
	.produit-selectnouv-item:first-child .articleBit-ima {margin-bottom: 0;}
	.produit-selectnouv-wrapper {margin: 0 }
	.btn-link.minw-auto{min-width: 100px}
}


@media only screen and (max-width:420px) {
.btn-link.minw-sm{min-width: 135px}

}
@media only screen and (max-width:390px) {
	.produit-selectnouv-item{padding: 0 5px;}
	.produit-selectnouv-wrapper {margin: 0 5px }

}

@media only screen and (max-width:380px) {
.btn-link.minw-sm{min-width: 130px}
}
@media only screen and (max-width:340px) {
.btn-link.minw-sm{min-width: 120px}
}

/*===================
 		TOP
 ==================*/
 .visible-ml{display:none}

@media only screen and (max-width:1300px) {
.contenu-art-top .swiper-container {padding-left: 10px; padding-right: 10px}
.padding-nouv-index {padding-left: 55px;padding-right: 55px;}
.prev-top{left: 2px;height: 50px;background-size: auto 100px;width: 24px;}
.next-top{right: 2px;height: 50px;background-size: auto 100px;width: 24px;}
}

@media only screen and (max-width:1200px) {
.swiper-pagination{position:relative;bottom:0!important; padding:13px 0}
.bloc-top-content {padding: 0;}
 .visible-ml{display:block}

 .content-ajout-panier{padding-right: 8px;}
 	.btn-ajout-panier{font-size: 14px}
 	 input.input-qte-a{width: 30px}
}
@media only screen and (max-width:1023px) {
	.titre-top-content {padding: 15px 0}
	.padding-nouv-index {padding-left: 35px;padding-right: 35px;}

}
@media only screen and (min-device-width : 767px) and (max-device-width :992px) and (orientation : portrait) {
.liste-articles  h3.articleBit-lib  {height: 40px;}
.btn-login .btn-link{padding-left: 5px; padding-right: 5px;}
}
@media only screen and (max-width:991px) {

	.parent-search{position: fixed;bottom: 60px;left: 0;width: 100%;padding: 10px;background: #000;-ms-transform: translateY(0);transition: all 0.3s;-webkit-transition: all 0.3s;-moz-transition: all 0.3s;-o-transition: all 0.3s;
    -ms-transition: all 0.3s;opacity: 0;visibility: hidden;transform: translateY(10px);-webkit-transform: translateY(10px);-ms-transform: translateY(10px);-o-transform: translateY(10px);-spec-transform: translateY(10px);z-index: 999;}
    .open-parent.parent-search {opacity: 1;visibility: visible;transform: translateY(0);-webkit-transform: translateY(0);-ms-transform: translateY(0);-o-transform: translateY(0);-spec-transform: translateY(0);}
    .search-bottom {padding: 0 5px;background: #fff;}
    .search-top{max-width: 100%;}
	.s-text{padding-left: 15px;}
	.s-submit{right: 5px;}
		.bloc-slider .swiper-pagination{position: absolute;width: 100%;}
		.bloc-slider .swiper-pagination-bullet{background: #fff;}
		.bloc-slider .swiper-pagination-bullet.swiper-pagination-bullet-active{background: #45A89E;;}

}
@media only screen and (max-width:767px) {
.titre-top-content > strong{padding:0;font-size:30px; }
h3.articleBit-lib{min-height: 40px}
.prev-top, .next-top{display:none}
h3.articleBit-lib a {font-size: 17px;line-height: 20px;height: 40px;padding-right: 8px;}

.articleBit-prix{font-size:16px;}
.prix-promo{font-size: 17px;}
.padding-nouv-index {padding-left:1px;padding-right: 1px;}

.etiquette{top: 10px;    font-size: 12px;padding: 2px 14px;right: -6px;}
.etiquette::after{border-width: 6px 6px 0 0;bottom: -6px;}
.etiquette.eti-new{right: 10px;width: 40px;height: 40px;padding-top: 11px;}
.picto-promo{right: 10px;width: 40px;height: 40px;padding-top: 11px;bottom: 10px;   }
	.picto-promo strong{ font-size: 12px;}
	.parent-search{bottom: 50px;}
}
@media only screen and (max-width:575px) {

.bloc-slider .swiper-pagination{padding: 10px }

}
@media only screen and (max-width:420px) {
	.parent-search {bottom: 46px;}
}

@media only screen and (max-width:380px) {
h3.articleBit-lib a {font-size: 15px;}

.articleBit-prix{font-size:14px;}
.prix-promo{font-size: 15px;}

}
@media only screen and (max-width:340px) {
h3.articleBit-lib a {font-size: 13px;}

.articleBit-prix{font-size:12px;}
.prix-promo{font-size: 13px;}
}

/**===================================
				FOOTER
=====================================*/
.toogle-menu2-mob{display:none}
@media only screen and (max-width: 1200px){
	.max-avantage .col-xl-3.col-md-auto.col-6.mb-sm-3.mb-3.mb-md-0.text-center {min-width: 20%;}
	.copyright{padding-top: 25px; padding-bottom: 25px;}
}

@media only screen and (max-width: 1080px){
.bg-fixed-reseau{height: 300px; background-position: center bottom!important;}
}

@media only screen and (max-width: 991px){
	.menu-content{margin-bottom: 15px}
	.logo-footer img {max-width: 230px;}
	.box-av{font-size: 14px;}
	.bloc-newsletter {font-size: 17px;line-height: 20px;}
	.news-button::after {content: "";width: 1px;height: 28px;top: 50%;transform: translate(0, -50%);-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);-spec-transform: translate(0, -50%);left: 0;position: absolute;background: rgba(178,177,177,0.75);}
}

@media only screen and (max-width: 767px){
.bg-fixed-reseau{height: 270px}
.box-av strong {min-width: 55px;}
.ico-reseau img {max-height: 30px;}
}

@media only screen and (min-width: 576px){
.toggle-m-c{display: block!important;height: 100%!important; transition: none!important;overflow: visible!important;}
.titre-menu-t.toggle-me{display: block!important;}
.reseau-bloc h3 br{display: none;}
.menu-content {max-width: 250px; }
}

@media only screen and (max-width: 575px){
	.max-reseau {padding-top: 25px;padding-bottom: 25px;    max-width: 85%;}
	.hidden-575{display: none;}
.bg-fixed-reseau{height: 230px}
	.ico-reseau img {max-height:25px;}

.bloc-paiement-footer img {max-width: 91px;}
.text-opti{font-size: 12px;}
	.menu-content{margin-bottom: 0px}

	.toggle-me {background: #E3E8D5;padding: 16px 10px;text-align: center;position: relative;border-bottom: 1px solid rgba(0,0,0,1);display:block!important;color: #45A89E}
		.body-sylius_shop_homepage .toggle-me{background-color: #ffffff }
		.service-footer .toggle-me {border-bottom: none;}
		.toggle-me::after{ content: '\e8ca';display:block; position:absolute; right:25px; top:50%;font-family: "fontello";   font-size: 14px;transform:translate(0,-50%);-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);-spec-transform: translate(0, -50%);font-weight:normal;color: #000;}
		.toggle-me.open,.body-sylius_shop_homepage .toggle-me.open, #page-panier .toggle-me.open{background-color: #45A89E; color: #fff;border-bottom-color: #809982;}

		.toggle-me.open::after{ content: '\e8cd';color: #fff}
		.titre-menu-t span{margin-bottom: 0; font-size: 16px}
		.menu-content ul li a{font-size: 17px}

	.menu-content ul li{text-align:center; width:100%}

	.service-footer {border-bottom: 1px solid rgba(0,0,0,0.3);}
.toggle-me + .toggle-m-c{display:none; padding:20px;margin-top: 0;text-align:center;background-color: #E3E8D5;}
.body-sylius_shop_homepage .toggle-me + .toggle-m-c{background-color: #ffffff}
	.toggle-m-c >span,span.lib-none {display:none}
 .toggle-m-c{text-align:center;}
.other-menu-bit{width:100%;    padding: 0}
.box-av{text-align: center;font-size: 19px;}
.box-av strong {padding-bottom: 10px;}
.box-av span {padding-left: 0;}
.bloc-paiement-footer {max-width: 300px;margin-left: auto!important;margin-right: auto!important;}
.footer-bottom{padding-top: 0;padding-bottom: 0;background-image: none!important;}
.content-reso-l{padding-top: 15px;}
}

@media only screen and (max-width: 480px){
	.max-reseau { max-width:95%;}
	.ico-reseau{margin-left: 15px; margin-right: 15px;}
	.titre-paiement{font-size: 14px;}
	.ico-reseau img {max-height:23px;}
}

@media only screen and (max-width: 390px){
.box-av{font-size: 16px}
.avantage-footer {margin-left: -10px;}
	.max-reseau { max-width: 100%;padding-top: 20px;padding-bottom: 20px;}
		.ico-reseau img {max-height:22px;}
		.bg-fixed-reseau{height: 210px; padding-right: 15px; padding-left: 15px;}
		.bloc-paiement-footer img {max-width: 80px;}
		.content-reso-l{padding-top: 10px;}
}
@media only screen and (max-width: 370px){
.box-av{font-size: 15px}
.titre-menu-t span{font-size: 15px}
.menu-content ul li a{font-size: 15px}
.logo-footer img {max-width: 200px;}
.titre-paiement{font-size: 13px;}
}
@media only screen and (max-width: 340px){
	.logo-footer img {max-width: 180px;}
	.copyright{font-size: 12px;}
	.bloc-paiement-footer img {max-width: 70px;}
	.titre-paiement{font-size: 12px;background-size:auto 18px;}
}
/**===================================
				PROJECT
=====================================*/
.visible-mobile-p{display: none;}
@media only screen and (max-width: 1200px){
	.titre-box{font-size: 22px}
	.fixed-px-total{position: fixed;}
}
@media only screen and (max-width: 991px){
	.titre-step-project{min-width: 700px}
	.max-container-4.px-20 {padding-left: 15px;padding-right: 15px;}
	.titre-box{font-size: 20px}
	.btn-box-project.btn-select-design{max-width: 260px; margin-right: auto;; margin-left: auto;width: 100%;}
	.h-100.video-bloc-project{width: 100%}
	.step-project-bit2 .padding-perso-40{padding-left: 0; padding-right: 0}
	.mb-perso-30{margin-bottom: 15px}
		.form-perso-content .custom-file-label::after, .step-contact .custom-file-label::after{min-width: 120px;}
		.form-perso-content .custom-file-label, .step-contact .custom-file-label{font-size: 13px; padding-right: 125px}
}
@media only screen and (max-width: 767px){
	.titre-step-project{min-width: 600px}
	.titre-step-project strong {font-size: 20px;}
	.font-regular-18{font-size: 1.6rem ;}

	.titre-box{font-size: 24px}
	.page-project::after{display: none;}
	.step-project-bit2 .padding-perso-40{padding-top: 10px}

		.flex-grow-l.desc-hide-hover p {margin-bottom: 7px;}
			.ariane-box{padding-top: 12px; padding-bottom: 12px;display: none;}
}
@media only screen and (min-width: 641px){
	.titre-step-project strong br{display: none;}
}
@media only screen and (max-width: 640px){
	.titre-step-project{min-width: 200px; display: block;}
	.titre-step-project strong {display: flex;display: -ms-flex; text-align: left;align-items: center;-ms-flex-align: center;font-size: 21px}
.visible-mobile-p{display: block;}
.hidden-mobile-p{display: none!important;}
.titre-step-project strong span.color-vert2 {font-size: 66px;line-height: 54px;padding-right: 10px;}
	.step-project-bit2  .titre-box{font-size: 20px}
		.step-project-bit2  .titre-box img{max-width: 115px;margin-top: -4px;}
		.surface-content{font-size: 20px;    padding-left: 30px;padding-right: 30px;}
		.flex-grow-l.desc-hide-hover {padding-left: 5px;padding-right: 5px;}
.img-bloc-project {padding-top: 10px;}
.font-regular-18{font-size: 1.5rem ;}
}
@media only screen and (min-width: 576px){
	.step-contact  .parent-ok-file>label::after{display: none;}
}
@media only screen and (max-width: 575px){
	.padding-perso-30{padding-left: 12px; padding-right: 12px; padding-bottom: 15px}

	.padding-perso-30 .font-seize.font-regular-site>span {display: block;padding-left: 0!important;}
	.step-contact {padding: 15px;}
	/********custom file******/
	.form-perso-content .custom-file, .step-contact .custom-file{height: 50px}
	.form-perso-content .custom-file-label, .step-contact .custom-file-label{height: 50px;padding-top: 16px;font-size: 14px}

	.content-client .custom-file-input, .perso-file-custom input[type="file"],.contact-container .custom-file-input{min-height: 50px;}
	.form-perso-content .custom-file-label::after, .step-contact .custom-file-label::after{line-height: 39px;min-width: 120px;font-size: 13px}
	.step-contact .label-ok-file.custom-file-label::before{display: none!important}
	.btn-add-panier-p{padding-top: 35px; padding-bottom: 35px}
}
@media only screen and (max-width: 480px){
	img.img-hover-block{max-height: 40%}
	.flex-grow-l.desc-hide-hover p{font-size: 12px}
	.padding-perso-30  .font-seize.font-regular-site small {font-size: 11px;}
	.prix-total-project, .prix-total-project-label{font-size: 24px}
	.page-project-content{font-size: 13px}
	.font-regular-18{font-size: 1.25rem ;}
	.georgia-font-step6{font-size: 15px;}
	.avp-bit{font-size: 14px}

}
@media only screen and (max-width: 390px){
		.max-container-4.px-20 {padding-left: 10px;padding-right: 10px;}
			.titre-step-project strong {font-size: 18px;}
			.titre-step-project strong span.color-vert2 {font-size: 60px;line-height: 48px;}
				.titre-box{font-size: 22px}
					.step-project-bit2  .titre-box{font-size:18px}
					.step-project-bit2  .titre-box img{max-width: 110px}
				.flex-grow-l.desc-hide-hover p{font-size: 11px}
				.titre-box.titre-box-small {font-size:18px;}
				.flex-grow-l.desc-hide-hover {padding-left: 0px;padding-right: 0px;}
}
@media only screen and (max-width: 370px){
		.step-project-bit2  .titre-box{font-size:16px}
		.step-project-bit2  .titre-box img{max-width: 100px}
		.btn-box-project .btn-link{min-width: 110px;font-size: 13px;}

		.flex-grow-l.desc-hide-hover p{font-size: 10px}
		.titre-box.titre-box-small {font-size:17px;}
		.step-project-bit3 .font-regular-19 label{font-size: 17px}
			img.img-hover-block{max-height: 45%}
			.step-project-bit3 .row.mx-lg-n2.mx-n2{margin-right: -4px!important; margin-left: -4px!important}
			.step-project-bit3  .col-xl-3.col-lg-4.col-6 {padding-left: 4px!important;padding-right: 4px!important;margin-bottom: 8px!important;}
			.padding-perso-30{padding-left: 10px; padding-right: 10px}
			.page-project-content{font-size: 12px}
			.font-seize{font-size: 14px}
			.font-size-normal {font-size: 13px;}
			.padding-perso-30 .font-seize.font-regular-site small {font-size: 10px;}
			.step-project-bit3  .btn-box-project .btn-link{width: 100%}
			.prix-total-project, .prix-total-project-label{font-size: 22px}
			.form-perso-content .custom-file-label::after, .step-contact .custom-file-label::after{min-width: 100px;font-size: 12px}
			.form-perso-content .custom-file-label, .step-contact .custom-file-label{font-size: 12px; padding-right: 105px}
			.avp-bit{font-size: 13px;margin: 0 -5px;}
			.avantage-photo{padding-left: 0;padding-right: 0;}
			
}
@media only screen and (max-width: 340px){
		.titre-step-project strong {font-size: 16px;}
			.titre-step-project strong span.color-vert2 {font-size: 55px;line-height: 45px;}
				.titre-box{font-size: 20px}
				.page-project-content{font-size: 11px}
					.step-project-bit2  .titre-box{font-size: 15px}
					.surface-content{font-size: 18px;}
					.step-project-bit .form-perso-content .form-check-label{padding-left: 30px;background-size: 18px;}
					.font-seize{font-size: 13px}
					.font-size-normal {font-size: 12px;}
					.padding-perso-30 .font-seize.font-regular-site small {font-size: 9px;}
					.form-perso-content .form-control, .step-contact .form-control{font-size: 12px;}
					.btn-box-project .btn-link {min-width: 100px;font-size: 12px;padding-top: 12px;padding-bottom: 12px;}
						.prix-total-project, .prix-total-project-label{font-size: 20px}
						.with-label-form .form-group>label{font-size: 14px}
							.btn-add-panier-p{padding-top: 28px; padding-bottom: 28px; font-size: 24px}
}
 /*=====================================
				 PANIER
 =======================================*/
 .lib-mobile{display:none; font-size:12px;  vertical-align:middle}
 .visible-680{display:none}
 @media only screen and (max-width:1650px) {
	.aide-article-fixed{display: none;}
 }
  @media only screen and (max-width:1250px) {
  	.padding-30-panier {padding-left: 15px;padding-right: 15px;}
  	.sylius-product-name{font-size: 32px}
  	.pxtotal-panier-item{font-size: 18px}
  	.panier-caract-bit>label{font-size: 18px}
  	.surface-panier{font-size: 22px}

  }

 @media only screen and (max-width:1024px) {
.etape-panier-conteneur {padding-top: 20px;padding-bottom: 20px;}
}
  @media only screen and (max-width:991px) {
  .max-pj-panier {max-width: 100%;}
  .etape-panier{margin-bottom: 30px}
}
  @media only screen and (max-width:767px) {
  .resume-table-panier{max-width: 100%; margin-top: 15px}
   .sylius-product-name{font-size: 30px;}
   .padding-30-panier {padding-bottom: 15px;padding-top: 15px;}
}

 @media only screen and (max-width:600px) {
	 .table-perso .card-header th, .table-perso .card-header {font-size: 15px;padding-left: 5px; padding-right: 5px;}
	 .table-perso tbody td{font-size: 14px;padding-left: 5px; padding-right: 5px;}
	 .table-perso .sylius-total{font-size: 15px;}
	 .sylius-product-name{font-size: 25px;}
	 .table-perso .sylius-quantity{max-width: 60px;}
	 .titre-h2{font-size: 20px;}
	 .btn-perso-c{font-size: 15px;}
	  .btn-dark-perso{font-size: 15px;}

	 .btn-paie{font-size: 22px;}
	 .cart-container.article-top {padding-left: 5px; padding-right: 5px;}
 }

 @media only screen and (max-width:576px) {
	 .cart-container.article-top {padding-left: 0; padding-right: 0;}
	 .table-perso span.sylius-quantity input.form-control{font-size: 13px;width: 50px;background:none;height: 30px;}
	 .titre-h2{font-size: 18px;}
	 .input-perso-content input.form-control{font-size: 13px;}
	 .cart-container .row.mb-5{margin-left: -5px; margin-right: -5px;}
	 .cart-container .row.mb-5>div{padding-left: 5px; padding-right: 5px;}
	 #sylius-cart-grand-total, .total-cart-summ{font-size: 19px;}
	 .etape-panier-bit{font-size: 16px}
	 .etape-panier-conteneur {padding-top: 10px;padding-bottom: 10px;}
	 	.pxtotal-panier-item{font-size: 16px}
  	.panier-caract-bit>label{font-size: 16px}
  	.surface-panier{font-size: 20px}

 }

 @media only screen and (max-width:400px) {
	 .table-perso .card-header th, .table-perso .card-header{font-size: 13px}
	 .table-perso .sylius-total{font-size: 13px;}
	 .sylius-product-name{font-size:22px;}
	 .btn-perso-c{font-size: 14px;}
	 .btn-dark-perso{font-size: 14px;}
	 #sylius-cart-grand-total, .total-cart-summ{font-size: 18px;}
	 .btn-paie{font-size: 20px;}
	 .table-perso span.sylius-quantity input.form-control{width: 40px;}
	  .etape-panier-bit{font-size: 15px; padding-right: 10px; padding-left: 10px}
 }
  @media only screen and (max-width:370px) {
  	.total-list-group {font-size: 18px;}
  	.contenu-panier{font-size: 14px;}
  	 .etape-panier-bit{font-size: 14px}
  	 	.pxtotal-panier-item{font-size: 15px}
  	.panier-caract-bit>label{font-size: 15px}
  	.surface-panier{font-size: 18px}
  	.btn-panier-cmd {padding-top: 20px;padding-bottom: 20px;font-size: 24px;}
  	.paiement-panier .img-secure img {max-width: 70px;}
  }
 /***********checkout************/
 @media only screen and (max-width:820px) {
	 .content-adress .steps-item{padding-left: 5px; padding-right: 5px;line-height: 50px;}
	 .content-adress .steps .steps-item .steps-title{font-size: 18px;}
	 .content-adress .steps .steps-icon{font-size: 18px;}
	 .form-perso-content input.form-control,.form-perso-content select.form-control {font-size: 13px;}
 }

 @media only screen and (max-width:640px) {
	 .content-adress .steps-item{line-height: 40px;}
	 .content-adress .steps .steps-item .steps-title{font-size: 16px;}
	 .content-adress .steps .steps-icon{font-size: 16px;padding-right: 10px;}
	 .content-adress .svg-inline--fa{vertical-align: middle;}
	 .card-body.form-perso-content {padding-left: 10px;padding-right: 10px;}
 }
 @media only screen and (max-width:480px) {
	 .content-adress .steps-item{line-height: 38px;}
	 .content-adress .steps .steps-item .steps-title{font-size: 15px;}
	 .content-adress .steps .steps-icon{font-size: 15px;}
	 .form-perso-content input.form-control,.form-perso-content select.form-control {font-size: 12px;}
	 .dropdown.address-book-select{font-size: 12px;}
 }
 @media only screen and (max-width:400px) {
	 .content-adress .steps .steps-item .steps-title{font-size: 13px;}
	 .content-adress .steps .steps-icon{font-size: 13px;padding-right: 5px;}
 }
 @media only screen and (max-width:360px) {
	.content-adress .steps .steps-item .steps-title{font-size: 12px;}
	.content-adress .steps .steps-icon{font-size: 12px;margin-top: -5px;}
 }
 @media only screen and (max-width:330px) {
	.content-adress .steps .steps-item .steps-title{font-size: 11px;}
	.content-adress .steps .steps-icon{font-size: 11px;padding-right: 2px;}
 }

 /*=================================
		 CLIENT
 ===================================*/
 @media only screen and (max-width: 1250px){
	 .container.my-0.page-account-content {max-width: 100%;}
.titre-compte-c, .content-bottom-order #order-invoices h3#shipping-state{font-size: 32px;}
.padding-lr-40{padding-left: 20px; padding-right: 20px}
.list-group-client .list-group-item.padding-30-all {padding: 15px;}
.mb-40{margin-bottom: 30px}
 }

 @media only screen and (max-width: 1140px){
	 .nom-user strong{font-size:19px}
 /******ADRESSE******/
	 .list-adress .btn-outline-secondary {font-size: 12px;}
	 .titre-compte-c, .content-bottom-order #order-invoices h3#shipping-state{font-size: 30px;}
 }

 @media only screen and (max-width: 1080px){
	 .table-histo-account.table-perso .card-header th, .table-histo-account.table-perso td {font-size: 12px;padding-left: 2px;padding-right: 2px;}
	 .table-histo-account.table-perso .action-table-perso .btn-link{/*font-size:14px;*/padding-left: 5px;padding-right: 5px}
	 #order-invoices table td, #order-invoices table th {font-size: 12px;padding-left: 5px;padding-right: 5px;}
	 .p-3.content-bottom-order .table-perso .col-12.col-lg-4 {padding-left: 0;}
	 .share-dash img{max-width: 30px}
	 	.border-left-account .btn-box-project .btn-link {font-size: 13px;padding-left: 10px;padding-right: 10px;min-width: 140px;}
 }
 @media only screen and (max-width: 991px){
 	.titre-compte-c, .content-bottom-order #order-invoices h3#shipping-state{font-size: 28px;}

	 .navbar-nav + .d-md-none {display: none;}
	 .titre-compte-client .titre-light-art{padding-left: 41px;}
	 .titre-compte-client p.mb-4 {margin-bottom:15px!important}
	 /******ADRESSE******/

	 .image-bienvenu img{max-width: 150%;}
	 .p-3.content-bottom-order .table-perso .col-12.col-lg-4 {padding-left: 15px;}
	 .max-width-left-dash {width: 100%;}
	 .list-group-client .titreregular-small{font-size: 18px}
	 .list-adress .btn.btn-icone{line-height: normal;}
 }

 @media only screen and (max-width: 767px){
	 .page-account-content .row.overflow-hidden {position: relative;}
	 .col-12.col-md-4.sylius_shop_account_dashboard + .col-md-8 {display: none;}
	 .image-bienvenu h1{display: none;}
	 .image-bienvenu img{top:-19%}
	 .nom-client-p {padding: 0!important;margin-bottom: 10px;margin-top: -55px;z-index: 9;position: relative;text-align: center;}
	 .nom-user {display: block;width: 100%;margin-top: 10px;padding: 0 10px 0 10px;}

	 .menu-left {display: none;overflow:visible!important;}
	 .sylius_shop_account_dashboard .menu-left{display: block;}
	 .menu-client >.row>.col-12{padding-left: 8px;padding-right: 8px;}
	 .text-upppercase.ssttire-client.text-uppercase {padding-top: 10px;}
	 /******ADRESSE******/
	 .list-adress button.btn.btn-link.btn-valider.rounded-0.border-0.text-uppercase.text-white {font-size: 15px;padding: 15px;}
	 .list-adress .card-body{padding-left: 10px; padding-right: 10px;}
	 .titre-compte-client .titre-light-art{font-size: 18px;}

	 .image-bienvenu img {max-width: 150%;height: auto!important;}
	 .page-sylius_shop_account_dashboard{position: relative;margin-top: 0!important;}
	 .nom-user strong {font-size: 23px;}
	 .page-instit-img h2{font-size: 1.2rem;}
	 .font-min-mobile{font-size: 13px}
	 .pdf-account .titre-box.titre-box-small{font-size: 16px}
	  	.titre-compte-c, .content-bottom-order #order-invoices h3#shipping-state{font-size: 26px;padding-bottom: 0;}
		  .titre-compte-client {position: relative;padding-left: 45px;}

 }

 @media only screen and (max-width: 600px){
	 .content-bottom-order .table-perso .card-header th {padding-left: 5px;padding-right: 5px;}
	 .content-bottom-order .table-perso .sylius-product-name {font-size: 25px;}
 }

 @media only screen and (max-width: 575px){
	 .table-histo-account .sylius-table-column-shippingAddress , .table-histo-account .sylius-table-column-date_updated_at{display: none!important;}
	 .page-sylius_shop_account_order_show th.sylius-table-column-subtotal.text-right.border-bottom-0 {width: 95px;}
	  .font-min-mobile{font-size: 12px}
	   	.titre-compte-c, .content-bottom-order #order-invoices h3#shipping-state{font-size: 24px;}
	   	.h-200{height: 150px}

 }

 @media only screen and (max-width: 480px){
	 .table-histo-account	.action-table-perso>a {display: block;margin-bottom: 1px;}
	 .titre-compte-client .titre-light-art{font-size: 16px;}
	 .content-bottom-order .table-perso .card-header th{font-size: 13px;}
	 .page-sylius_shop_account_order_show th.sylius-table-column-price.text-center.border-bottom-0 {display: none;}
	 .page-sylius_shop_account_order_show table#sylius-order td:nth-child(2){display: none;}
	 .content-bottom-order .table-perso .sylius-product-name {font-size: 22px;}
	 .content-bottom-order .table-perso .card-header,.content-bottom-order #order-invoices table th{font-size: 13px;}
	 .page-instit-img h2{font-size: 1rem;}
	  .pdf-account .titre-box.titre-box-small{font-size: 14px}
	  .error-notfound{font-size: 80px;}
 }

 @media only screen and (max-width: 380px){
	 .table-histo-account  .sylius-table-column-state {display: none;}
	 .ariane-box .breadcrumb-item+.breadcrumb-item:before{padding-right: 2px;}
	 .ariane-box .breadcrumb-item+.breadcrumb-item{padding-left: 2px;}
	  .font-min-mobile{font-size: 11px}
	  .padding-lr-40 {padding-left: 10px;padding-right: 10px;}
	  .error-notfound{font-size: 60px;}
 }
  @media only screen and (max-width: 370px){
  	.border-left-account .btn-box-project .btn-link {font-size: 12px;padding-left: 5px;padding-right: 5px;min-width: 120px;width: 100%}
  	 	.titre-compte-c, .content-bottom-order #order-invoices h3#shipping-state{font-size: 23px;}
		   .table-histo-account.table-perso .action-table-perso .btn-link{font-size: 11px;}
  }
  @media only screen and (max-width: 340px){
	.titre-compte-c, .content-bottom-order #order-invoices h3#shipping-state{font-size: 20px;}
	.titre-compte-client {padding-left: 38px;}

  }
