.clear{float:none;clear:both;width:0;height:0;}
ul{margin:0;padding:0;}
	ul li{list-style:none;margin:0;padding:0;}
a{border:none;outline:none;text-decoration:none;color:inherit;}
a:focus, a:hover{text-decoration:none!important;outline:none;color:#809982;}

button:hover{text-decoration:none!important;}
.btn.focus, .btn:focus{box-shadow:none!important;-webkit-box-shadow:none!important;-ms-box-shadow:none!important;-o-box-shadow:none!important; color:#fff!important}
button:focus, select:focus, input[type="text"]:focus,input:focus, textarea:focus {outline:none;box-shadow:none!important;-webkit-box-shadow:none!important;-ms-box-shadow:none!important; }
.custom-file-input:focus~.custom-file-label{box-shadow:none!important;-webkit-box-shadow:none!important;-ms-box-shadow:none!important;-o-box-shadow:none!important}

img{border:none;max-width:100%;}

body{font-family:"Sofia Pro-Light", Arial;color:#000000;font-size:13px;line-height: 1.5;}
	.big-wrapper{background:#ffffff;overflow-x: hidden;}
		.bg-blanc{background-color:#ffffff;}
select{-webkit-appearance:none; -moz-appearance:none;appearance:none;-ms-appearance:none;-o-appearance:none;cursor:pointer}
/**remove select apparence IE****/
select::-ms-expand {display: none;}

.opacity-hover:hover{
	filter:brightness(110%);
	-moz-filter:brightness(110%);
	-webkit-filter:brightness(110%);
	-ms-filter:brightness(110%);
	-o-filter:brightness(110%);
	}

.color-white{color:#ffffff!important}
.color-vert2{color:#809982!important}
.color-vert{color:#45A89E!important}
.bg-vert2{background:#809982!important}
.bg-vert-label{background:#45A89E!important}

.bg-white{background:#ffffff!important}
.bg-rose{background:#fff2e4!important}
.menu-mob-overlay{background:rgba(26,26,26,0.5);height:100%;left:0;position:fixed;top:0;width:100%;z-index:99; display:none}
.menu-overlay{background:rgba(96,96,96,0.6);height:100%;left:0;position:fixed;top:0;width:100%;z-index:99; display:none}
.line-through{text-decoration:line-through}
	.visible-980{display:none}
.clear-float::after{content:""; display:block; clear:both}
.pi-uppercase{text-transform:uppercase}
.font-seize{font-size: 16px}
.btn-link.font-seize{font-size: 16px!important}
.font-size-normal{font-size: 14px}
.transition-bgcolor{ -webkit-transition: background-color 0.5s ease-out;
  -moz-transition: background-color 0.5s ease-out;
  -o-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out;}
.hidden {visibility: hidden;}
/********inout design********/
		.input-abs {position: absolute;z-index: 1;width: 100%;left: 0;top: 0;height: 100%;cursor: pointer;opacity: 0;}
		.input-rel {position:relative; z-index:1; margin:0 5px 10px 0}
@media (min-width: 1320px){
.container, .container-lg, .container-md, .container-sm, .container-xl {max-width: 1600px;}
}

.line-through{text-decoration:line-through}
.stat, .stat img, .opti, .opti img {position: absolute;left: 0;height: 0;top: -9000px;}
.just-one .swiper-pagination span, .just-one .swiper-pagination{display:none!important}
 .just-one .swiper-wrapper {transform: none!important;-webkit-transform: none!important;-ms-transform: none!important;-o-transform: none!important;-spec-transform: none!important;}
 .h1-index{display:none}
.page-index .h1-index{display:block; color:rgba(0,0,0,0); font-size:1px; position:absolute; left:0; top:0}
.justify-center .swiper-wrapper {-webkit-box-pack: center!important;-ms-flex-pack: center!important;justify-content: center!important;}
input::-webkit-input-placeholder, .form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */color: #000;opacity:1;}
input::-moz-placeholder, .form-control::-moz-placeholder { /* Firefox 19+ */color: #000;opacity:1;}
input:-ms-input-placeholder, .form-control:-ms-input-placeholder { /* IE 10+ */color:  #000;opacity:1;}
input:-moz-placeholder ,.form-control:-moz-placeholder  { /* Firefox 18- */color:  #000;opacity:1;}
/*=====================================
 				COMMUN
 =======================================*/
.header-sticky {padding:0 }
.header-body {position:relative;z-index:10;background-color: #ffffff;background-repeat: no-repeat;background-position: top center;border-bottom: 2px solid #809982}
.header-sticky>.container {position: relative;z-index: 2;}
.top-header { font:14px "Sofia Pro"; padding:8px 10px; color: #fff; background-color: #000}
		.phrase-admin { font:12px "Sofia Pro-Light";color: #809982;}
		.phrase-admin p {margin:  0;}

	.link-top-ul >li {padding: 0 10px;}
		.link-top-ul >li>a {display: block;position: relative;font-size:14px;padding-bottom: 2px; padding-top: 2px;padding-left: 25px;color:#ffffff}
			.link-top-ul >li>a::after {display: block;background-position: 0;background-repeat: no-repeat;content: "";position: absolute;width:20px;height: 20px;top: 5px;left: 0;}
					.link-top-ul >li>a.link-ctc-top::after {background-image: url("../images/ico_phone.svg");background-position: center;top: 2px}

					.link-top-ul >li>a.pro-ctc-top::after {background-image: url("../images/ico_pro.svg");background-position: center;top: 0}

					.link-top-ul >li>a.compte-ctc-top::after {background-image: url("../images/ico_compte.svg");background-position: center;top: 0}
						.link-top-ul >li>a.panier-ctc-top{padding-left: 0;padding-top: 0;position: relative;}
						.link-top-ul >li>a.panier-ctc-top .visible-hover{top: 3px;}
						.link-top-ul >li>a.panier-ctc-top:hover .visible-hover{opacity: 1;-webkit-opacity: 1;-ms-opacity: 1;-o-opacity: 1;visibility: visible;}
				.link-top-ul >li>a.panier-ctc-top::after{display: none;}

					.link-top-ul >li>a.link-top-language{padding-left: 0; padding-right: 20px;margin-top: -5px; }
					.link-top-ul >li>a.link-top-language::after {background-position: -15px -4px;left:auto;right: 0;background-image: url("../images/ico_linktop.svg");height: 13px; width: 20px;}

						.link-top-language  img {display: inline-block;}

					.link-top-ul >li>a:hover{color: #809982; text-decoration: none!important;}
				.langue-list {position: absolute;right: 0;width: 74px;text-align: center;background: #000;padding: 10px 5px;border: 1px solid #000;opacity: 0;transform: translateY(30px);-webkit-transform: translateY(30px);-ms-transform: translateY(30px);-o-transform: translateY(30px);-spec-transform: translateY(30px);transition: all 0.3s;-webkit-transition: all 0.3s;-moz-transition: all 0.3s;-o-transition: all 0.3s;-ms-transition: all 0.3s;visibility: hidden;color:#fff;margin-top: 9px;z-index: 999;}
				.langue-list li  {margin-bottom: 5px;}
				.focus.langue-list{opacity: 1;visibility:visible;transform: translateY(0);-webkit-transform: translateY(0);-ms-transform: translateY(0);-o-transform: translateY(0);-spec-transform: translateY(0);transition:all 0.3s;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-o-transition:all 0.3s;-ms-transition:all 0.3s;}
.link-mobile-fixed {position: fixed;width: 100%;bottom: 0;left: 0;z-index: 9999;background: #000;display: none}
	.link-mobile-fixed a {border-left: 1px solid rgba(255,255,255,0.2);padding: 5px 15px;border-top: 1px solid rgba(255,255,255,0.2)}
	.link-mobile-fixed a:focus,.link-mobile-fixed a:hover{background-color:#809982 }

#header{padding:10px 0;transition:all 0.5s;-webkit-transition:all 0.5s;-moz-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s;position:relative;z-index: 2;}
	.visible-mobile{display:none}
	.visible-mobile-767{display:none}

		.logo-site img{transition:all 0.5s;-webkit-transition:all 0.5s;-moz-transition:all 0.5s;-o-transition:all 0.5s;width: 100%;max-width: 400px;}
			.logo-site a {position: relative;display: inline-block;}

	.text-perso-header{font:14px "Sofia Pro"; height:14px}
	.search-top{position:relative;max-width:375px; margin:auto; }


	.other-link-top {    margin-left: auto;max-width:290px;}
		.li-m980{display:none}
		.other-link-top li{float: left; padding: 0 15px;text-align:center}
		.other-link-top li a{padding:  0; display: block}

			a.compte-link.link-other {background: url("../images/ico_phonemobile.svg") no-repeat  50% -3px;width: 30px;height: 30px;background-size: auto 60px;}
				a.compte-link.link-other:hover {background-position: 50% -33px;}

				.content-user-account {-ms-transform: translateY(0);transition:all 0.3s;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-o-transition:all 0.3s;-ms-transition:all 0.3s;position: relative;top:100%;right: 0;width: 100%; opacity: 0; visibility: hidden;transform: translateY(10px);-webkit-transform: translateY(10px);-ms-transform: translateY(10px);-o-transform: translateY(10px);padding-top: 0px;z-index: 99;}
				.show-account.content-user-account {opacity: 1;visibility:visible;transform: translateY(0);-webkit-transform: translateY(0);-ms-transform: translateY(0);-o-transform: translateY(0);}

			a.panier-link.link-other {background: url("../images/ico_panierm.svg") no-repeat  50% 0;width: 30px;height: 25px}
				a.panier-link.link-other:hover {background-position: 50% -25px;}

			.s-text {width: 100%;padding: 2px 35px 2px 0;color: #1b1b1b;font: 13px "Sofia Pro";height: 32px;background: #fff;border:none;border-bottom: 1px solid #B3B3B3;}

			.s-text::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			  color: #1b1b1b;
			  opacity:1;
			}
			.s-text::-moz-placeholder { /* Firefox 19+ */
			  color: #1b1b1b;
			   opacity:1;
			}
			.s-text:-ms-input-placeholder { /* IE 10+ */
			  color:  #1b1b1b;
			   opacity:1;
			}
			.s-text:-moz-placeholder { /* Firefox 18- */
			  color:  #1b1b1b;
			   opacity:1;
			}
			.s-submit{ background: url('../images/ico_recherche.svg') no-repeat center top;border: none;color: #87743b;height: 30px;position: absolute;right: 0;top: 50%;padding: 5px 10px;transform: translate(0, -50%);-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);-spec-transform: translate(0, -50%);}
			.s-submit:hover{background-position: center bottom;}

	/***********panier deroulant *********/
.panier-deroulant {-ms-transform: translateY(0);transition:all 0.3s;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-o-transition:all 0.3s;-ms-transition:all 0.3s;position: absolute;right: 0;width: 410px; opacity: 0; visibility: hidden;transform: translateY(10px);-webkit-transform: translateY(10px);-ms-transform: translateY(10px);-o-transform: translateY(10px);padding-top: 8px;z-index: 99;color:#000}
.hover-cart-popup:hover .panier-deroulant {opacity: 1;visibility:visible;transform: translateY(0);-webkit-transform: translateY(0);-ms-transform: translateY(0);-o-transform: translateY(0);}

.content-panier-deroulant{background-color: #ffffff;padding: 10px;top: 100%; border:1px solid #e5dcd3;}
.deroulant-panierBit {border-bottom: 1px solid rgba(178,177,177,0.75);padding: 10px 0;position: relative;  margin-right: 5px;}
.nbre-panier{position: absolute;right: -10px; top: 0; background-color: #809982; color: #fff; font : 12px "Sofia Pro";border-radius: 100%;-webkit-border-radius: 100%;-ms-border-radius: 100%;-o-border-radius: 100%;-spec-border-radius: 100%; padding-top: 0px; display: block;width: 17px; height: 17px; text-align: center;}
.image-panier-der {display: inline-block;width: 84px;padding-right:8px;vertical-align: middle}
span.image-panier-der .image-slide {background: #f7f6f5;padding: 5px;}
	.detail-panierBit {display: inline-block;vertical-align: middle;width:calc(100% - 88px);text-align: left;}
	.detail-panierBit h3{font: 14px "Sofia Pro-Medium";color:#000000}

		.detail_bottom {margin-top: 15px;}
		.image-bienvenu >img{border:1px solid #e5dcd3; }
		.detail-bottom > span {display: inline-block;padding-top: 3px;}
		strong.total-panier-der {font-weight:700 ; font-size: 17px;}
		.px-deroulant-panier {display: block;float: right;font-size:15px; font-weight:normal }
		.delete_derbit {background: none  ;border: none;color: #ea2828 ;display: block;padding: 2px 0;position: absolute;right: 0;text-align: center;top: 13px;width: 20px;z-index: 99;font-size: 21px;}
.panier-bottom {padding: 10px 0;}

	body.no-scrolling{overflow:hidden!important}
.menu-mobile {float: right;width: 30%;display:none; margin-top:10px}
	.menu-mobile > div {float: left;text-align: center;width: 50%;}
		.menu-mobile-bloc a {display: inline-block;position: relative;max-width:45px; text-align:center}
		.menu-mobile-bloc span {margin-top: 2px;font: 14px "Sofia Pro";padding-top: 2px;display: block;}

		.menu-mobile-bloc {position: absolute;top: 50%;left: 0;transform: translate(0, -50%);-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);-spec-transform: translate(0, -50%);padding-top: 0;z-index: 99;display: none;}
	.close-menu-mob {display: none;position: absolute;color: #fff;right:0;top: 0;z-index: 1;background: #000;width:38px;height: 38px;text-align: center;padding: 8px;}
	.span-menu-mob{display: none;position: absolute;color: #ffffff;font: 20px "Sofia Pro-Medium";left: 23px;top: 7px;z-index: 1;text-transform: uppercase;}

	.menu-top {position: relative;z-index: 99;}
	.max-200{max-height:216px; overflow-x:hidden!important; overflow-y:hidden}
	.max-100{max-height:100px; overflow-x:hidden!important; overflow-y:hidden}

/*****IIE STYLE*******/

@media only screen and (min-width:1200px) {

}
@media only screen and (min-width:993px) {
.container-big-lg{max-width: 98%; margin:auto;}
.header-body.no_zindex{z-index: 9991;}
}

/*=================================
			NAVIGATION
==================================*/

.navigation-site{background-color:#fff;position:relative; z-index:1;}


	ul.onglets {display: table;text-align: center;margin-left: auto;}
		li.navItem {display: table-cell;text-align: center;vertical-align: middle;padding:0 ; position: relative;}

				a.navLink{display:block;color:#000;font:13px "Sofia Pro";padding:15px 20px;position:relative; z-index:99999;transition:all 0.3s;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-o-transition:all 0.3s;-ms-transition:all 0.3s;border:none;position: relative; text-transform: uppercase; }

.menu-overlay {background: rgba(45, 45, 44, 0.5);height: 100%;left: 0;position: fixed;top: 0;width: 100%;z-index: 9;}
.arianeBox{padding:15px 0;color:#8c8e93;}


body:not(.modal-open) .page-content{z-index: 3}
.page-index .texte-promo{display:block}
.page-index .texte-promo.visible-mobile{display:none!important}

@media only screen and (min-width:1201px) {
	.logo-site img{min-width: 400px;}
	.header-body.headerFixed{position:fixed;width:100%;left:0;top:0;z-index:999; }
	.navigation-site{min-width: calc( 100% - 450px );}
	.header-body.headerFixed #header{
	transition:all 0.5s;-webkit-transition:all 0.5s;-moz-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s;padding:0}

	.headerFixed  a.navLink{transition:all 0.5s;-webkit-transition:all 0.5s;-moz-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s; }
	.padding-body{padding-top:120px}
	.header-body.relative {position: relative;z-index: 9999;background-color:#ffffff}

	.headerFixed .slogan-site-header{display: none;}

.headerFixed .center-search {display: none;}
		.headerFixed .logo-site img {max-width:200px;min-width: 200px}
		.headerFixed  .right-bloc-head {position: absolute;right: -15px;top: 9px;z-index: 99;}
			.headerFixed  a.ctc-link.link-other {font-size: 0;}
			.headerFixed .navigation-site .container{max-width: 100%;}
	}
	.logo-site h1 {position: absolute;font-size: 0;height: 0;width: 0;left: 0;color: #fff;}
@media only screen and (min-width:1025px) {
	.navigation-site{display:block!important;}
	/*.header-body.relative * {z-index: 9999;}*/
	.liste-article .pi-row {margin: 0 -8px;}

}

@media only screen and (min-width:993px) {


	.navigation-site .mCustomScrollBox, .navigation-site .mCSB_container {position: static!important;overflow: visible;margin-right: 0!important;}
	.navigation-site  .mCSB_scrollTools{display: none!important;}
		li.navItem:hover >a.navLink{color: #45A89E;transition:all 0.3s;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-o-transition:all 0.3s;-ms-transition:all 0.3s;}
}

.max-container-2{max-width: 1400px; margin:auto; padding: 0 10px }
.max-container-3{max-width: 1200px; margin:auto; padding: 0 10px }
.max-container-4{max-width: 1300px; margin:auto; padding: 0 10px }
.max-container-5{max-width: 1520px; margin:auto; padding: 0 10px }

@media only screen and (min-width:1025px) {

	.px-20{padding-left: 20px; padding-right: 20px}
}
/*====================================
			SLIDER
======================================*/
.swiper-container.slider-home {z-index: initial;}
.conteneur-slider{position:relative; z-index:1; text-align:center}
	.texte-slider-perso {width: 100%;}
		.texte-stepbox-slider p{margin-bottom: 0;}
.content-flex {position: relative;margin: auto;max-width: 1100px;background: rgba(237,237,237,0.4);padding: 20px;color: #fff;}

.bb-other-step {background: url("../images/bg_slider.png") no-repeat top left;padding: 40px;}
	.img-stepbox-slider {min-height: 45px;display: inline-block;position: relative;}

.numstep {position: absolute;left: -18px;bottom: 1px;padding: 2px;width: 24px;height: 24px;display: block;background: #45A89E;color: #fff;border-radius: 100%;-webkit-border-radius: 100%;-ms-border-radius: 100%;-o-border-radius: 100%;-spec-border-radius: 100%;font:  15px "DKLemonYellowSun"; text-align: center;}
	.titre-stepbox-slider {font: 20px "Sofia Pro";padding-top: 10px;}
	.stepbox-slider {position: relative;}
.after-ligne {position: absolute;width: 100%;height: 1px;max-width: 78%;background: #809982;left: 63%;top: 26px;}
.after-ligne::before {content: "";width: 5px;height: 5px;background: #809982;border-radius: 100%;-webkit-border-radius: 100%;-ms-border-radius: 100%;-o-border-radius: 100%;-spec-border-radius: 100%;position: absolute;left: 0;top: -2px;}
.after-ligne::after {content: "";width: 5px;height: 5px;background: #809982;border-radius: 100%;-webkit-border-radius: 100%;-ms-border-radius: 100%;-o-border-radius: 100%;-spec-border-radius: 100%;position: absolute;right: 0;top: -2px;}
.bb-other-step .titre-perso-medium {font-size: 40px;}
.bb-other-step .titre-perso-medium span {display: block;text-transform: none;color: #809982;font-family: 'Sofia Pro-Light';}
.step-slider-content {position: relative;}
	.texte-ccm{position: absolute;left:40px;top: -55px}
.bloc-slider  .swiper-pagination {position: relative;padding:15px 0; bottom: 0}
	.bloc-slider .swiper-pagination-bullet{margin:0 4px}
		.conteneur-g-slider{background: rgba(19,45,52,0.94);
background: -moz-radial-gradient(center, ellipse cover, rgba(19,45,52,0.94) 0%, rgba(15,33,43,0.94) 90%, rgba(14,32,42,0.94) 100%);
background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(19,45,52,1)), color-stop(90%, rgba(15,33,43,0.85)), color-stop(100%, rgba(14,32,42,0.85)));
background: -webkit-radial-gradient(center, ellipse cover, rgba(19,45,52,0.94) 0%, rgba(15,33,43,0.94) 90%, rgba(14,32,42,0.94) 100%);
background: -o-radial-gradient(center, ellipse cover, rgba(19,45,52,0.94) 0%, rgba(15,33,43,0.94) 90%, rgba(14,32,42,0.94) 100%);
background: -ms-radial-gradient(center, ellipse cover, rgba(19,45,52,0.94) 0%, rgba(15,33,43,0.94) 90%, rgba(14,32,42,0.94) 100%);}
.home-next:hover,.home-prev:hover{background-position:center bottom}
.slider-item.swiper-slide img {width: 100%;max-height: 750px;}

.bb-other-step .bg-vert-s.btn-link, .bb-other-step .bg-blanc-s.btn-link{font-size: 14px;padding-top: 22px; padding-bottom: 22px; margin: 0 15px;}

@media only screen and (min-width:992px) {
	.carou-slide.swiper-wrapper {height: 600px;}
	.slider-item img{height: 100%; width: 100%;object-fit: cover;object-position: center;}
		.texte-slider-perso {position: absolute;left: 0;top: 0;z-index: 9;padding-left: 10px; padding-right: 10px;margin-top: -25px;}
}
@media only screen and (min-width:1180px) {
	.carou-slide.swiper-wrapper {height: 680px;}
	.bloc-slider .swiper-pagination {padding: 40px 0 15px 0;bottom: 35px;border-radius: 100%;background: #fff;width: 110%;left: -5%;}
}
/*===============================
	 NOUVEAUTE
================================*/
.padding-nouv-index{padding-right: 80px; padding-left: 80px;}
.bg-gris{background-color: #F2F2F2}
.max-container-1 {max-width: 1030px;margin:0 auto;}
	.titre-medium {font-size: 42px;}
	.titre-bigmedium {font-size: 48px;}
	.titre-light-med {font : 30px "Sofia Pro-Light";text-transform: uppercase;}

	.titre-light-big {font : 40px "Sofia Pro-Light";text-transform: uppercase;}
	.titre-light-verybig {font : 50px "Sofia Pro-Light";text-transform: uppercase;}
		.text-capitalize-perso{text-transform: none!important;}
		.text-capitalize-perso:first-letter{text-transform: uppercase!important;}
	.texte-gris {color: #4D4D4D;}

		.titre-light-art {font : 40px "Sofia Pro-Light";text-transform: lowercase;}


	.titre-light-art:first-letter{text-transform: uppercase;}
	.sofiaregular-16 {font: 16px "Sofia Pro";}
	.sofiaregular-14 {font: 14px "Sofia Pro";}
	.sofiaMedium-font {font-family: "Sofia Pro-Medium";}
	.titreregular-small {font: 20px "Sofia Pro";}
	.top-nouveaute {padding-left: 8px;padding-right: 8px;}
	.btn-link {background: #fff;display: inline-block;min-width: 160px;text-align: center;border: 1px solid #45A89E;color: #000;padding: 19px 15px;font: 12px "Sofia Pro-Medium"; text-transform: uppercase;cursor: pointer;}
	.btn-link.minw-sm{min-width: 160px;font-family:"Sofia Pro"}
	.btn-link.minw-verybig{min-width: 240px;}

	.btn-link:hover, .btn-link:focus {color:#fff; background-color: #45A89E!important}
	.btn-link.color-vert:hover, .btn-link.color-vert:focus {color:#fff; background-color: #42C6B3!important}
	.bg-vert-s.btn-link{background: #45A89E;color: #fff;}
	.bg-vert-s.btn-link:hover,.bg-blanc-s.btn-link:focus {background: #fff!important;color: #45A89E;}
    .bg-blanc-s.btn-link{background: #fff;color: #45A89E;}
    .bg-blanc-s.btn-link:hover,.bg-blanc-s.btn-link:focus {background: #45A89E!important;color: #fff;}
	.hover-img-scale{cursor: default;}
	.img-about img{transition:all 0.5s;-webkit-transition:all 0.5s;-moz-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s}
	.hover-img-scale:hover  .img-about img {transform: scale(0.9);-webkit-transform: scale(0.9);-ms-transform: scale(0.9);}

	.btn-link.has-icon span {display: block;text-align: left;}
span.icon-1 {padding-left: 30px;position: relative;}

span.icon-1::after {width: 30px;height: 25px;content: "";background: url("../images/av4.svg") no-repeat left;position: absolute;left: 0;top: 50%;transform: translate(0, -50%);-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);-spec-transform: translate(0, -50%);background-size: auto 25px;}
@media only screen and (min-width:1025px) {
.expand-para-mobile .summary {display: none!important;}
.expand-para-mobile .details {display: block!important;height: auto!important;}
.expand-para-mobile  .read-less{display: none!important;}
}
@media only screen and (min-width:768px) {
.expand-parasm-mobile .summary {display: none!important;}
.expand-parasm-mobile .details {display: block!important;height: auto!important;}
.expand-parasm-mobile  .read-less{display: none!important;}
}

/*===============================
		COLLECTION INDEX
================================*/
.bg-vert-opac{background-color: #E3E8D5;}
.bloc-collection-index .bg-vert-opac{background: #E3E8D5 url("../images/bg_collection.png") no-repeat right bottom;}
.bloc-collection-index {max-width: 1300px;margin-left: auto;margin-right: auto;font-size: 14px;}
.titre-perso-medium {font-size: 25px;padding-bottom: 25px;}
	.titre-perso-medium strong {display: block;font-size: 40px;font-weight: normal;line-height: 100%;}
.titrelarge-regular {font: 55px "Sofia Pro";}
.text-regular{font-family:"Sofia Pro" }
.text-sofiaMedium{font-family:"Sofia Pro-Medium" }
.max-container-txt{max-width: 450px}
.max-container-txt2{max-width: 900px; margin: auto; padding: 0 10px}
.object-fit-img img{height: 100%; object-fit: cover; width: 100%}


@media only screen and (min-width:768px) {
	.bloc-collection-index .object-fit-img img {min-height: 400px;}
}



/**********ARTICLLEBIT**************/
.bg-art-w {padding: 20px;background: #fff;}
.max-art{margin-top: 3px}
	h3.articleBit-lib{text-align:left;padding:0;  margin:0 0 5px 0;}
		h3.articleBit-lib a{font:14px 'Sofia Pro-Medium';color:#000;display: inline-block;}
				.table-lib {width:100%}
		.articleBit-prix {text-align: left;margin:0 0 10px 0;font:14px 'Sofia Pro'; position:relative;}
						.prix-promo{font:16px 'Sofia Pro-Medium';display:inline-block;color:#000}
						.apartir{font:13px 'Sofia Pro';color:#000;padding-right: 5px; }
							.prix-old{text-decoration: line-through;color:#637971;margin-left: 5px}

	.articleBit-ima {text-align: center;position: relative;}
		.flex-grow-l{-webkit-box-flex: 1;-ms-flex: 1 1 auto;flex: 1 1 auto;}
		.etiquette {position: absolute;right:-8px;top: 15px;background: #809982;color: #fff;padding: 5px 18px;text-align: center;}

	.etiquette.etiquette-3 {right: 15px;width: 48px;height: 48px;border-radius: 100%;padding-top: 13px;padding-right: 0; padding-left: 0;}
		.etiquette::after {content: "";position: absolute;width: 0;height: 0;border-style: solid;border-width: 8px  8px 0 0;border-color:#8AA84A transparent transparent transparent ;right: 0px;bottom: -8px;}
		.etiquette.etiquette-3::after{display: none;}
		.etiquette:empty {display: none;}

.picto-promo {position: absolute;right: 15px;width: 45px;height: 45px;border-radius: 100%;-webkit-border-radius: 100%;-ms-border-radius: 100%;-o-border-radius: 100%;-spec-border-radius: 100%;padding-top: 13px;padding-right: 0; padding-left: 0;text-align: center;background-color: #809982;bottom: 15px;}
.picto-promo strong{font: 13px "Sofia Pro-Medium"; color: #fff;display: block;}

/*===============================
		QUOTE TXT INDEX
================================*/
q {quotes: "\201C" "\201D" "\2018" "\2019";}
.container-max-quote{max-width: 650px; margin: auto;}
.quote-design {position: relative;margin-top: 15px;padding: 10px 70px;min-height: 100px; font:italic 700 17px "Georgia";color: #809982}
.quote-design q::after {font-size: 125px;font-family:Georgia;font-style: normal;font-weight: normal;color: #809982;position: absolute;right: 0;bottom: -5px;line-height: 30px;display: block;}
.quote-design q::before {font-size: 125px;font-family: Georgia; font-style: normal;font-weight: normal; color: #809982;position: absolute;left: 0;top:25px;line-height: 30px;display: block;}

/*===============================
		INSPIRATION INDEX
================================*/
.bloc-inspiration {background: #000000 url(../images/bg_inspiration.png) no-repeat left top;color: #fff;}
.titre-perso-big{font-size: 40px;}
@media only screen and (min-width:1520px) {
.bloc-inspiration .row	.px-2{    padding-left:5px!important; padding-right:5px!important;}
.bloc-inspiration .box-show.mb-sm-2.mb-3{margin-bottom: 10px!important;}
.bloc-inspiration .box-show.mb-md-0.mb-sm-2.mb-3{margin-bottom: 0px!important;}
}
/*===============================
		ABOUT  INDEX
================================*/
.max-container-about{max-width: 960px; margin: auto;}
	.max-container-about p {margin-bottom: 1rem;}
.bloc-about{font-size: 14px;}
/*===============================
		SELECTION INDEX
================================*/
.bg-perso-w {background-color: rgba(0,0,0,0);color: #fff;border-color: #fff;}
.bg-perso-dore{background-color: rgba(0,0,0,0);color: #809982;border-color: #809982;}
.btn-link.bg-perso-w:hover,.btn-link.bg-perso-w:focus {background: rgba(255,255,255,0.5)!important; color:#000;}
.btn-link.bg-perso-dore:hover,.btn-link.bg-perso-dore:focus {background:#809982!important;color: #fff;}

.bg-perso-black{background-color: #000;color: #fff;border-color: #000;}
.btn-link.bg-perso-black:hover,.btn-link.bg-perso-black:focus {background-color: #333!important;border-color: #333!important;}

.container-min-max{max-width: 1070px}
.overflow-hover{overflow:hidden;height: 100%}
.big-link-hover{position: absolute;display: block;width: 100%; height: 100%; left: 0; top: 0}
.overflow-hover  img{transition:all 0.5s;-webkit-transition:all 0.5s;-moz-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s; height: 100%; width: 100%; object-fit: cover}
.overflow-hover a:hover >img{transform: scale(1.05);-webkit-transform: scale(1.05);-ms-transform: scale(1.05);-o-transform: scale(1.05);}
.overflow-hover:hover .absolute-box-index .btn-link{background: rgba(255,255,255,0.5)!important; color:#000;}

/*===============================
		FOOTEr
================================*/

.goToTop {bottom: 115px;cursor: pointer;display: none;position: fixed;right: 17px;text-align: center;width:60px;z-index: 9999;padding: 0; background:#809982}
.goToTop:hover {background:#63C6B8 }

/********REASSURANCE*******/
.max-avantage{max-width: 1020px; margin:auto;}
.box-av{font: 16px "Sofia Pro"; display: block;text-align: left;}
.box-av strong{padding-bottom:0;transition:all 0.5s;-webkit-transition:all 0.5s;-moz-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s;}
.box-av span{line-height: normal;padding-left: 15px;transition:all 0.5s;-webkit-transition:all 0.5s;-moz-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s;}
@media only screen and (min-width: 576px){
.box-av:hover strong{margin-right: -5px; padding-left: 5px}
.box-av:hover span{color: #809982;padding-left: 10px }
}


/*****RESEAU SOCIO**********/
	.bg-reseau-bloc{background: rgba(19,45,53,1);
background: -moz-radial-gradient(center, ellipse cover, rgba(19,45,53,1) 0%, rgba(14,32,42,1) 100%);
background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(19,45,53,1)), color-stop(100%, rgba(14,32,42,1)));
background: -webkit-radial-gradient(center, ellipse cover, rgba(19,45,53,1) 0%, rgba(14,32,42,1) 100%);
background: -o-radial-gradient(center, ellipse cover, rgba(19,45,53,1) 0%, rgba(14,32,42,1) 100%);
background: -ms-radial-gradient(center, ellipse cover, rgba(19,45,53,1) 0%, rgba(14,32,42,1) 100%);
background: radial-gradient(ellipse at center, rgba(19,45,53,1) 0%, rgba(14,32,42,1) 100%);}
.bg-fixed-reseau{height: 400px;background: url("../images/bg_reso.jpg") no-repeat center;background-size: cover;padding-left: 25px; padding-right: 25px;}


.max-reseau {padding: 40px 15px;border: 1px solid #809982;width: 100%;max-width: 600px;}
.reseau-bloc h3.titre-light-big strong {font: 29px "Sofia Pro Light";text-transform: none;display: block;letter-spacing: 3px;color: #63C6B8;}

.reseau-bloc h3.titre-light-big {letter-spacing: 3px;}

.ico-reseau{display: inline-block;; margin: 0 20px;position: relative;}
		.visible-hover{position: absolute;left: 0; top: 0;z-index:9 ; opacity: 0;-webkit-opacity: 0;-ms-opacity: 0;-o-opacity: 0;visibility: hidden;transition: all 0.2s;-webkit-transition: all 0.2s;-moz-transition: all 0.2s;-o-transition: all 0.2s;-ms-transition: all 0.2s; }

		.ico-reseau:hover .visible-hover{opacity: 1;-webkit-opacity: 1;-ms-opacity: 1;-o-opacity: 1;visibility: visible;}


/* Pulse */
@-webkit-keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
@keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
.hvr-pulse img{
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-pulse:hover img, .hvr-pulse:focus img, .hvr-pulse:active  img{
  -webkit-animation-name: hvr-pulse;
  animation-name: hvr-pulse;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

/******NEWSLETTER******/
.newsletterSection {padding: 20px 40px 10px;}
.newsletterSection > form {display: flex; flex-direction: row; max-width: 600px !important; margin: 0 auto;}
.newsletterSection > form > div:first-of-type {width: 100%;}
.newsletterSection button {background: #45A89E;color: #fff;cursor: pointer;min-width: 140px;border: 1px solid #45A89E;padding: 19px 15px;font: 12px "Sofia Pro-Medium";text-transform: uppercase; margin: 0 auto;}
.newsletterSection .title {text-align: center; text-transform: uppercase;font-weight: bold;font-size: 3rem;color: #45A89E;}
.newsletterSection > p {text-align: center;}
.newsletterSection .infoMsg {display: none; width: 100%; background-color: rgba(69, 168, 158, 0.3); padding: 5px 10px; border-radius: 5px; max-width: 600px; margin: 15px auto 0;}

/******OPINIONS******/
.opinionsSection {padding: 20px 50px; margin: 50px auto 30px;}
.opinionsSection .title {text-align: center; text-transform: uppercase;font-weight: bold;font-size: 3rem;color: #45A89E;}
.opinionsSection > .opinion-system-widget-company-rating {max-width: 400px; margin: 20px auto 0;}

@media only screen and (max-width: 500px) {
    .newsletterSection .title, .opinionsSection .title {font-size: 2rem;}
}
@media only screen and (max-width: 350px) {
    .newsletterSection .title, .opinionsSection .title {font-size: 1.2rem;}
}

/********MENU**********/
.footer-bottom {padding: 70px 0;background: #fff url("../images/bg_footer.png") no-repeat right 5% bottom -10px;}
.bloc-footer-menu {font: 14px "Sofia Pro-Light";}
.text-underline {text-decoration: underline;}
.titre-menu-t {font : 17px "Sofia Pro"; text-transform: uppercase;padding-bottom: 15px; margin-bottom: 0; color: #45A89E;}

	ul.toggle-m-c li {margin-bottom: 15px ;}
	.bloc-footer-menu li a {color: #000000;}
.bloc-footer-menu li a:hover{color: #45A89E;}
.phone-footer{font: 22px "Sofia Pro-Light";margin-top: 2px;}
.contact-footer {display: inline-block;padding-left: 30px;background: url("../images/ico_mail.svg") no-repeat center left;color:#000000}
.contact-footer:hover{color: #809982;}
.copyright{font:13px "Sofia Pro-Light";color: #969797;background: #000;padding: 12px 30px;}
	.copyright a{color: #969797;}
	.copyright a:hover{color: #45A89E;}

.titre-paiement {font: 13px "Sofia Pro";color: #fff;padding: 6px 23px 6px 0;background: url(../images/ico_paiement.png) no-repeat center right;margin-right: 10px;}
	.logo-footer img {max-width: 260px;}
.bloc-paiement-footer img{max-width: 94px;}
.border-paiement a {display: block;border: 1px solid #809982;position: relative;}

.border-paiement a:hover{border-color: #63C6B8;}
.link-paiement-f:hover .visible-hover{opacity: 1;-webkit-opacity: 1;-ms-opacity: 1;-o-opacity: 1;visibility: visible;}

@media only screen and (min-width: 1025px){
	.max-reseau{margin-top: 15px;}
	}
/*=============================
			PROJECT
===============================*/
p {line-height: normal;}

.ariane-box {padding: 20px 0;font: 14px "Sofia Pro-Light";color: #809982!important;}
 .ariane-box *{font:  12px "Sofia Pro-Light";color: #809982!important}
  .ariane-box .breadcrumb-item+.breadcrumb-item::before{content: ">";color: #809982!important}
#page-project section.reseau-bloc.mt-xl-5.pt-4,#page-panier section.reseau-bloc.mt-xl-5.pt-4 {margin-top: 0!important; padding-top:0!important;}
	.page-project-content {/*background: #E3E8D5 url("../images/bg_transparent_p.png") no-repeat center top;*/background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(226,231,212,1) 80%, rgba(226,231,212,1) 100%);
		background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(226,231,212,0.3)), color-stop(80%, rgba(226,231,212,1)), color-stop(100%, rgba(226,231,212,1)));
		background: -webkit-linear-gradient(top, rgba(226,231,212,0.3) 0%, rgba(226,231,212,1) 80%, rgba(226,231,212,1) 100%);
		background: -o-linear-gradient(top, rgba(226,231,212,0.3) 0%, rgba(226,231,212,1) 80%, rgba(226,231,212,1) 100%);
		background: -ms-linear-gradient(top, rgba(226,231,212,0.3) 0%, rgba(226,231,212,1) 80%, rgba(226,231,212,1) 100%);
		background: linear-gradient(to bottom, rgba(226,231,212,0.3) 0%, rgba(226,231,212,1) 80%, rgba(226,231,212,1) 100%);background-size: 100%;position: relative;padding-bottom: 60px;font-size: 14px;/*background-blend-mode: multiply;*/ }
	.page-project::after, .body-app_shop_configurator::after{content:  url("../images/after_bgproject.png"); position: absolute;left: 0;bottom: 15%}
		.titre-step-project {min-width: 765px;border: 1px solid #809982;margin: auto;display: inline-block;padding: 8px;}
		.titre-step-project strong {display: block;background: #0E202A;color: #fff;text-align: center; padding:13px 10px;font: 22px "Sofia Pro";}
.box-shadow-project {
    position: relative;
    background: #fff;
box-shadow: 0px 0px 9px 1px rgba(128,153,130,0.3);
-webkit-box-shadow: 0px 0px 9px 1px rgba(128,153,130,0.3);
-moz-box-shadow: 0px 0px 9px 1px rgba(128,153,130,0.3);}
.after-input-abs {border: 2px solid #fff;position: relative;}

	.titre-box {font: 25px "Sofia Pro";margin: 0;padding: 10px 0;}
.video-bloc-project >iframe {height: 100%;}
.btn-box-project {padding-top: 2px;}
	.btn-box-project .btn-link {font-size: 14px;padding-top: 14px;padding-bottom: 14px;}

	.btn-box-project.btn-select-design .btn-link{padding-left: 0; padding-right: 0}
		.btn-box-project .btn-link span {display: inline-block;padding: 2px 0 2px 0;}
		.btn-box-project.btn-select-design .btn-link span{padding-left:30px;padding-right:30px;}
	.flex-grow-l.desc-hide-hover p {font-family: "Sofia Pro-Light-Italic"}
	.flex-grow-l.desc-hide-hover{padding-left: 15px;padding-right: 15px;}

.input-abs-project {position: absolute;left: 0;width: 100%;top: 0;height: 100%;opacity: 0;cursor: pointer;z-index: 99;}
 .input-abs-project:checked + .after-input-abs,.input-abs-project:hover + .after-input-abs {border-color:#809982; background-color: #EDF2ED }
 .input-abs-project:checked + .after-input-abs .btn-box-project .btn-link, .input-abs-project:hover + .after-input-abs .btn-box-project .btn-link {background-color: #42C6B3}
 	.input-abs-project:checked + .after-input-abs .btn-box-project.btn-select-design .btn-link span {background: url(../images/ico_check.png) no-repeat center left;}

 	.padding-perso-40{padding-top: 40px; padding-bottom: 40px; padding-left: 15px; padding-right: 15px}
 	.padding-perso-30{padding:15px 30px 20px 30px}
 	.mb-perso-30{margin-bottom: 32px}
 	.font-regular-site{font-family: Sofia Pro}
.progress-container {max-width: 440px;margin:20px  auto;background: #EEEEEE;border: 1px solid rgba(198,198,198,0.6);border-radius: 20px;-webkit-border-radius: 20px;-ms-border-radius: 20px;-o-border-radius: 20px;-spec-border-radius: 20px;padding: 3px 15px;}

.progress-container  .progress {border-radius: 20px;-webkit-border-radius: 20px;-ms-border-radius: 20px;-o-border-radius: 20px;-spec-border-radius: 20px;height: 5px;width: 100%;background: #fff;overflow: visible}
	.progress-bar.bg-vert2 {position: relative;overflow: visible;}
	.progress-container .form-group {margin-bottom: 0!important;}
	.progress-container  .form-control-range {height: 5px;margin-top:0px;  -webkit-appearance: none;outline: none;background: #fff;box-sizing: border-box;cursor: pointer;border: 1px solid rgba(198,198,198,0.6);padding: 0;border-radius: 20px;-webkit-border-radius: 20px;-ms-border-radius: 20px;-o-border-radius: 20px;-spec-border-radius: 20px;background-image: linear-gradient(#809982, #809982);background-size: 50% 100%;background-repeat: no-repeat;}
	/* la zone de déplacement */
	.progress-container  .form-control-range::-webkit-slider-runnable-track, .progress-container  .form-control-range::-moz-range-track, .progress-container  .form-control-range::-ms-track   {height: 100%;border: none;	background-color: transparent;}
	.panier-caract-bit .progress-container .progress{border: 1px solid rgba(198,198,198,0.6);}
	.panier-caract-bit .progress-container .progress-bar{border-radius: 20px;-webkit-border-radius: 20px;-ms-border-radius: 20px;-o-border-radius: 20px;-spec-border-radius: 20px;}
  	/* le curseur */
	  .progress-container  input[type=range]::-ms-thumb {-webkit-appearance: none;width: 20px!important;height: 20px!important; margin-top:-4px;border-radius: 100%;-webkit-border-radius: 100%;-ms-border-radius: 100%;-o-border-radius: 100%;-spec-border-radius: 100%;background: #809982!important;box-shadow: -1px -1px 0px 1px rgba(63,81,64,1);-webkit-box-shadow: -1px -1px 0px 1px rgba(63,81,64,1);-moz-box-shadow: -1px -1px 0px 1px rgba(63,81,64,1);}

  .progress-container  .form-control-range::-webkit-slider-thumb{-webkit-appearance: none;width: 20px!important;height: 20px!important; margin-top:-4px;
	border-radius: 100%;-webkit-border-radius: 100%;-ms-border-radius: 100%;-o-border-radius: 100%;-spec-border-radius: 100%;background: #809982!important;box-shadow: -1px -1px 0px 1px rgba(63,81,64,1);-webkit-box-shadow: -1px -1px 0px 1px rgba(63,81,64,1);-moz-box-shadow: -1px -1px 0px 1px rgba(63,81,64,1);}

	/* All the same stuff for Firefox */
.progress-container input[type=range]::-moz-range-thumb {
	-webkit-appearance: none;width: 20px!important;height: 20px!important; margin-top:-4px;border-radius: 100%;-webkit-border-radius: 100%;-ms-border-radius: 100%;-o-border-radius: 100%;-spec-border-radius: 100%;background: #809982!important;box-shadow: -1px -1px 0px 1px rgba(63,81,64,1);-webkit-box-shadow: -1px -1px 0px 1px rgba(63,81,64,1);-moz-box-shadow: -1px -1px 0px 1px rgba(63,81,64,1);
	cursor: pointer;
  }
  /* barre progression avant */
  .progress-container  .form-control-range::-moz-range-progress, .progress-container  .form-control-range::-webkit-progress-value, .progress-container  .form-control-range::-ms-fill-lower , ::-webkit-progress-value{background: #809982!important;}
  input[type="range"]::-webkit-slider-thumb:after {background: #809982!important;}
  /* barre progression après */
  .progress-container  input[type=range].form-control-range::-ms-fill-upper {background: transparent;}
  .progress-container input[type=range]::-moz-range-track {-webkit-appearance: none;box-shadow: none;border: none;background: transparent;}

  .progress-container input[type="range"]::-ms-track {-webkit-appearance: none;box-shadow: none;border: none;background: transparent;}

.progress-bar.bg-vert2::after {position: absolute;width: 20px;height: 20px;border-radius: 100%;-webkit-border-radius: 100%;-ms-border-radius: 100%;-o-border-radius: 100%;-spec-border-radius: 100%;content: "";right: 0;background: #809982;z-index: 99;box-shadow: -1px -1px 0px 1px rgba(63,81,64,1);-webkit-box-shadow: -1px -1px 0px 1px rgba(63,81,64,1);-moz-box-shadow: -1px -1px 0px 1px rgba(63,81,64,1);}
.surface-content {font: 22px "Sofia Pro";}
	.px-surface small{text-transform: uppercase;font-size: 11px; padding-left: 3px}
.iframe-min-height iframe{min-height: 230px;height: 100%;}
#info-geoportail {display: none;}
#info-geoportail > iframe {margin: 0 auto;}
button.info-geoportail {font-weight: bold; display: flex; margin: 0 auto;}
button.info-geoportail > i {display: flex; background-color: #1abb9c; color: #FFF; border-radius: 100%; width: 25px; height: 25px; justify-content: center; align-items: center; margin-right: 10px; font-size: 1.2rem;}
button.info-geoportail > span {text-decoration: underline; font-size: 1rem;}
button.info-geoportail:hover >span {text-decoration: none;}

/*******step3********/

	.para-light-italic{font-family: "Sofia Pro-Light-Italic";}
	 .input-abs-project:checked + .after-input-abs .desc-hide-hover, .input-abs-project:hover + .after-input-abs .desc-hide-hover {position: relative;}
	 .input-abs-project:checked + .after-input-abs .desc-hide-hover p, .input-abs-project:hover + .after-input-abs .desc-hide-hover p{opacity: 0;}

	 .titre-box.titre-box-small{font-size: 20px}
	  .input-abs-project:checked + .after-input-abs  .titre-box.titre-box-small,  .input-abs-project:hover + .after-input-abs  .titre-box.titre-box-small{opacity: 0;}
	  .desc-hide-hover .titre-box.titre-box-small{display: none;position: absolute;width: 100%;text-align: center;top: 50%; transform: translate(0,-50%);-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);-spec-transform: translate(0, -50%);left: 0;z-index: 9;opacity: 1!important;}
	  .input-abs-project:checked + .after-input-abs .desc-hide-hover .titre-box.titre-box-small, .input-abs-project:hover + .after-input-abs .desc-hide-hover  .titre-box.titre-box-small{display: block;}
	 img.img-hover-block {position: absolute;left:0;top: 0;z-index: 9;width: 100%;max-height: 170px;max-width: calc(100% + 4px);opacity: 0;background: #000;height: 100%;transition: all 0.3s;-webkit-transition: all 0.3s;-moz-transition: all 0.3s;-o-transition: all 0.3s;-ms-transition: all 0.3s;  transform: scale(1);-webkit-transform: scale(1);-ms-transform: scale(1);-o-transform: scale(1);}
	.img-bloc-project {padding-top: 15px;padding-bottom: 5px;}


	 .input-abs-project:checked + .after-input-abs  img.img-hover-block, .input-abs-project:hover + .after-input-abs  img.img-hover-block {opacity: 1;transform: scale(1);-webkit-transform: scale(1);-ms-transform: scale(1);-o-transform: scale(1);}
	 .prix-total-project{display: inline;}
.prix-total-project, .prix-total-project-label{font: 20px "Sofia Pro";}
.btn-add-panier-p{display:block;max-width: 400px;text-align: center;background-color: #45A89E;color: #fff;padding: 30px 15px;font: 25px "Sofia Pro";text-transform: uppercase;margin:auto;cursor:pointer}
.btn-add-panier-p:hover,.btn-add-panier-p:focus {color:#fff; background-color:#42C6B3 }
.disabled-link{cursor: not-allowed;/*pointer-events: none;*/text-decoration: none;background-color: #ccc!important;}

/*******step6********/
#configurator_projectFiles {padding: 20px 50px; border: 1px solid #809982; cursor: pointer; display: flex; flex-wrap: wrap; min-height: 224px; position: relative;}
#configurator_projectFiles > div {margin: 10px; position: relative; display: flex; flex-direction: column; align-items: center; max-width: 210px; width: 210px;}
#configurator_projectFiles .dz-progress {position: absolute;top: 0;width: 120px;height: 10px;}
#configurator_projectFiles .dz-upload {display: block; height: 10px; background-color: #0e8c8c; transition: all 0.2s; opacity: 0;}
#configurator_projectFiles .dz-success-mark, #configurator_projectFiles .dz-error-mark {opacity: 0; position: absolute; top: 0; transition: opacity 0.3s; background-color: #0e8c8c; border-radius: 100%; transform: scale(0.7);}
#configurator_projectFiles .dz-success-mark.active, #configurator_projectFiles .dz-error-mark.active {opacity: 1;}
#configurator_projectFiles .dz-success-mark svg, #configurator_projectFiles .dz-error-mark svg {fill: #FFF;}
#configurator_projectFiles .dz-details {text-align: center;}
#configurator_projectFiles .dz-image {min-height: 120px; max-width: 120px; width: 120px;}
#configurator_projectFiles .dz-image img {height: 120px; object-fit: cover;}
#configurator_projectFiles .btn-small {padding: 10px 5px!important;}
#configurator_projectFiles .dz-error-message {color: red;}
#configurator_projectFiles > .configurator_projectFiles_infos {position: absolute;top: 5px;display: block;width: 100px;left: calc(50% - 50px);font-weight: bold; color: #809982;}
.uplaod-infos .infos {font-style: italic; margin-bottom: 15px; color: #809982;}
.error-msg {color: red; font-size: 1.2rem; font-weight: bold; margin-top: 20px;}
.step6 .step-contact {padding-bottom: 60px;}
.step6 .step-contact > div:first-of-type > p {text-align: left;}

.step3 .form-perso-content > *, .step4 .form-perso-content > *, .step5 .form-perso-content > * {margin: 0 15px;}

.avantage-photo {background: rgb(19,45,53);background: radial-gradient(circle, rgba(19,45,53,1) 0%, rgba(14,32,42,0.94) 100%);background: -webkit-radial-gradient(circle, rgba(19,45,53,1) 0%, rgba(14,32,42,0.94) 100%);position: relative;max-width: 1100px;margin-left: auto;margin-right: auto;padding: 25px 15px;}
.avp-bit {font: 15px "Sofia Pro";color: #FFFFFF;}
.georgia-font-step6{font:italic 700 17px Georgia;color: #809982} 
.font-regular-18{font: 1.75rem "Sofia Pro";}

@media only screen and (min-width: 768px){
.btn-box-project .btn-link.perso-link-btn{padding-top: 10px;padding-bottom: 10px;}
}
@media only screen and (min-width: 993px){

.resume-table-panier.static-position {position: sticky;top: 1px;bottom: auto;}
.padding-body .resume-table-panier.static-position{top:105px;}
}

.fixed-px-total {position: sticky;bottom: 0;width: 100%;padding: 15px;background: #E3E8D5;text-align: center;z-index: 99;left: 0;}

.mw-auto{min-width: 80px!important;}
@media only screen and (min-width: 1200px){
	.fixed-px-total {position: fixed;top: 50%;background: #5b6e5c;text-align: center;z-index: 99999;right: 0;max-width: 185px;color: #fff;width: 100%;left:auto; bottom: auto;}

	.fixed-px-total.prix-total-project-label .prix-total-project {display: block;}
}
@media only screen and (max-width: 1200px){
.fixed-px-total.hide_fixed{display: none!important;}
}
.project-show.client-cmd-show p{margin-bottom: 1rem;}
/*=============================
		PANIER
===============================*/
.aide-article-fixed {position: fixed;right: 0;top: 30%;z-index: 999;width:100%; max-width: 185px}
	.aide-article-fixed span br {display: none;}
		.aide-article-fixed .content {background-color: #809982;padding: 15px ;position: relative;font-size: 14px ;color: #fff; }
		.aide-article-fixed .content>strong {font:22px "sofia Pro"; display: block;}
		.art_contact{font:19px "sofia Pro";padding: 5px 0 5px 20px;background: url('../images/phone_w.png') no-repeat center left;display: block;color: #fff!important}

.contenu-panier {/*background: #E3E8D5 url('../images/bg_transparent_p.png') no-repeat center top;*/background: -moz-linear-gradient(top, rgba(226,231,212,0.3) 0%, rgba(226,231,212,1) 80%, rgba(226,231,212,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(80%, rgba(226,231,212,1)), color-stop(100%, rgba(226,231,212,1)));
	background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(226,231,212,1) 80%, rgba(226,231,212,1) 100%);
	background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(226,231,212,1) 80%, rgba(226,231,212,1) 100%);
	background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(226,231,212,1) 80%, rgba(226,231,212,1) 100%);
	background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(226,231,212,1) 80%, rgba(226,231,212,1) 100%);background-size: 100%;position: relative;padding-bottom: 60px;font: 16px "Sofia Pro";    /*background-blend-mode: multiply;*/}
	.etape-panier-conteneur {padding-top: 30px;padding-bottom: 30px;}

.etape-panier {width: 100%;max-width: 630px;margin:0 auto 50px auto;text-align: center;}

.etape-panier-bit {width: 33.333%;font: 18px "Sofia Pro";color: #000;padding: 20px 15px;position: relative;}

	.etape-panier-bit.active-step {color: #45A89E;}

	.etape-panier-bit::before {content: "";width: 10px;height: 10px;border-radius: 100%;-webkit-border-radius: 100%;-ms-border-radius: 100%;-o-border-radius: 100%;-spec-border-radius: 100%;position: absolute;background: #000;left: 50%;bottom: 0;transform: translate(-50%, 0);-webkit-transform: translate(-50%, 0);-ms-transform: translate(-50%, 0);-o-transform: translate(-50%, 0);-spec-transform: translate(-50%, 0);z-index: 9}
	.etape-panier-bit.active-step::before{background: #45A89E}

	.etape-panier-bit::after {width: 100%;height: 1px;background: #000;content: "";position: absolute;bottom: 4px;left: 50%;z-index: 8}
	.etape-panier-bit.last-step-panier::after{display: none;}

.box-shadow-persos {position: relative;background: #fff;box-shadow: 0px 0px 9px 1px rgba(53,53,53,0.3);-webkit-box-shadow: 0px 0px 9px 1px rgba(53,53,53,0.3);-moz-box-shadow: 0px 0px 9px 1px rgba(53,53,53,0.3);}
.visible-hover-cart{position: absolute;opacity: 0;-webkit-opacity: 0;-ms-opacity: 0;-o-opacity: 0;visibility: hidden;left: 0;top: 0;    height: 100%;object-fit: cover;width: 100%;transition: all 0.3s;-webkit-transition: all 0.3s;-moz-transition: all 0.3s;-o-transition: all 0.3s;-ms-transition: all 0.3s;}
	.caract-panier-content .after-input-abs:hover .visible-hover-cart{opacity: 1;-webkit-opacity: 1;-ms-opacity: 1;-o-opacity: 1;visibility: visible;}
.padding-30-panier{padding: 30px}


.details-panier-item {position: relative;}
	.sylius-product-name {font: 36px "Sofia Pro";color: #809982;text-transform: uppercase;padding-right: 35px}

	.suppr-panier-btn {position: absolute;right: 0;top: 0;z-index: 9}

	.pxtotal-panier-item {font-size: 20px;margin-top: 10px;}
.panier-caract-bit {margin-bottom: 30px;}
.panier-caract-bit:last-child{margin-bottom: 0}
	.panier-caract-bit>label {font: 20px "Sofia Pro";}
	.max-progress{max-width: 460px;    position: relative;padding-top: 25px;}
	.surface-panier{font-size: 25px; color: #809982; position: absolute;top: 0;left: 50%; transform: translateX(-50%);-webkit-transform: translateX(-50%);-ms-transform: translateX(-50%);-o-transform: translateX(-50%);-spec-transform: translateX(-50%);}

.resume-table-panier{max-width: 520px; width: 100%}
	.code-promo{margin-bottom: 25px}
		.code-promo .form-control {height: 40px;border: none;background: #E6E6E6;border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;-spec-border-radius: 0;color: #000; font-size: 14px}
		.code-promo .input-group-append {width: 40px;}

		.btn-perso-code {background: #809982;font: 14px "Sofia Pro";color: #fff;text-transform: capitalize;padding: 5px;width: 100%;}
		.btn-perso-code:hover {background: #9AAF9B;color: #fff}
	.table-totale .list-group-item{border-color: #809982 }
	.table-totale .list-group-item:first-of-type{flex-wrap: wrap;}
    .table-totale .list-group-item:first-of-type > ul {width: 100%; margin-top: 10px;}
    .table-totale .list-group-item:first-of-type > ul li {display: flex; align-items: center; margin: 8px 0;}
    .table-totale .list-group-item:first-of-type > ul img {width: 20px; max-width: 20px;}

    .product-contain > ul {width: 100%; margin-top: 10px;}
    .product-contain > ul li {display: flex; align-items: center; margin: 8px 0;}
    .product-contain > ul img {width: 20px; max-width: 20px;}

	table#sylius-checkout-subtotal {color: #000;}
	#recapitulatif-total-commande .border-dark{border-color: #809982!important }

	.total-list-group {font-size: 20px;}
	.btn-panier-cmd{display: block;text-align: center;background-color: #45A89E;color: #fff;padding: 25px 15px;font: 25px "Sofia Pro";text-transform: uppercase;margin: auto;}
	.btn-panier-cmd:hover, .btn-panier-cmd:focus {color: #fff;background-color: #42C6B3;}

.box-caract-shadow {position: relative;background: #fff;box-shadow: 0px 0px 9px 1px rgba(53,53,53,0.3);-webkit-box-shadow: 0px 0px 9px 1px rgba(53,53,53,0.3);-moz-box-shadow: 0px 0px 9px 1px rgba(53,53,53,0.3); text-align: center;}
.box-caract-shadow .after-input-abs{padding:20px 10px 12px 10px;cursor: default;}
.caract-panier-content .form-group {margin-bottom: 27px;}
	.max-pj-panier{max-width: 100px}
.form-perso-content.check-panier-news .form-check{margin-top: 20px}
	.paiement-panier >strong {font: 13px "Sofia Pro";color: #809982;padding: 6px 23px 6px 0;background: url('../images/ico_paiement2.png') no-repeat center right;margin-right: 10px;}
	.paiement-panier .img-secure img {max-width: 82px;}
	.contenu-panier .alert.alert-info {margin-bottom: 0;}

	@media only screen and (min-width: 1200px){
		.resume-table-panier{width: 520px;}
	}
/*=============================
		FILE
===============================*/
.text-link-color a{color: #809982; text-decoration: underline;}
.custom-file-btn .custom-file-input {
    height: 100%;
    cursor: pointer;
}
.custom-file-btn .custom-file-input {position: relative;z-index: 2;width: 100%;height: calc(1.7em + 0.75rem + 2px);margin: 0;opacity: 0;}
.custom-file-btn .custom-file-label {background: #000;text-align: center;font: 18px "Sofia Pro-Light";padding: 12px 30px;color:#F2F2F2;border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;-spec-border-radius: 0;border: none;cursor: pointer;    display: block;position: relative;height: auto;max-width: 460px;margin: auto; }
.custom-file-btn  .custom-file-label::after{display: none;}
.font-italic{font-family: "Sofia Pro-Light-Italic"}
	.progress-content .progress {height: 7px;border: 1px solid #ccc;background: #fff;max-width: 200px;margin: auto;}
	.progress-content {padding-top: 27px;text-align: center;}
.bg-black{background-color: #000}
.titre-configbit{font : 35px "Sofia Pro-Light";padding-bottom: 20px}
.ekko-lightbox .modal-header {padding-top: 0;padding-bottom: 0;}

.ekko-lightbox .modal-header .close {font-size: 31px;}
/*=================================
		FORMULAIRe
==================================*/
.form-perso-content .form-check {display: inline-block;padding-left: 0;}
	.form-perso-content .form-check-input {width: 100%;margin: 0;opacity: 0;top: 0;height: 100%;cursor: pointer;}
	.form-perso-content .form-check-label {background: url("../images/check_off.png") no-repeat left center;padding-left:35px;line-height: 22px;background-size: 22px;}
	.form-perso-content.radio-label-input .form-check-label{background-image: url("../images/radio_off.png");padding-left:25px;line-height: 22px;background-size: 16px;}
	.body-sylius_shop_order_show .form-perso-content.radio-label-input input[type="radio"] + label{background: url("../images/radio_off.png") no-repeat left center;padding-left:25px;line-height: 22px;background-size: 16px;}
		.form-perso-content .form-check-input:checked +  .form-check-label {background-image: url("../images/check_on.png")}
		.form-perso-content.radio-label-input .form-check-input:checked +  .form-check-label{background-image: url("../images/radio_on.png")}
		.contact_form .form-group>label {font-size: 0;height: 0;margin-bottom: 0;line-height: 0;display: block;}
		.contact_form  span.form-error-message  {font-size: 12px;position: absolute;bottom: -6px;right:0;width: 100%;text-align: right;}
		.perso-formgroup-content   .form-group,.form-perso-content   .form-group  {position: relative;}
		.form-perso-content .ui.form  .field{position: relative;    margin-bottom: 1rem!important;}
		.perso-formgroup-content .invalid-feedback, .form-perso-content  .invalid-feedback{position: absolute;right: 0;bottom: -17px;text-align: right;width: 100%;}
		 .form-perso-content .option-prioritaire .invalid-feedback{    position: relative;text-align: left;bottom: 0;}

		.contact-container .form-perso-content  .invalid-feedback{bottom: 0;}
		form#app_form_upload .form-group {margin-bottom: 35px;}
		.form-perso-content.radio-label-input .has-logo-choice .form-check-label, .body-sylius_shop_order_show .form-perso-content.radio-label-input .has-logo-choice input[type="radio"] + label {padding-left: 80px;}
		.pl-mode {padding-left: 25px;font-family: "Sofia Pro-Light";font-size: 13px;}
		.form-check-logo.has-logo-choice .pl-mode {padding-left: 80px;}
		.img-logo-check {position: absolute;max-width: 50px;left: 22px;top: 50%;transform: translate(0, -50%);-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);-spec-transform: translate(0, -50%);margin-top: -4px;}
		.form-check-logo.has-logo-choice {position: relative;margin-top: 8px;}

		form#app_form_upload .form-perso-content  .invalid-feedback {text-align: center;font-size: 15px;bottom: -33px;font-weight: 600;}
		.font-regular-19 label{font:19px "Sofia Pro";}
		.p-3.form-perso-content.perso-formgroup-content span.form-error-message {position: relative;bottom: 0;right: 0;}
.form-perso-content.check-panier-news  .form-check-label{background-image: url("../images/check_off2.png"); font: 13px "Sofia Pro-Italic" ;  padding-left:25px;
    line-height: 21px;background-size: 19px;background-position: top left;}
.form-perso-content.check-panier-news  .form-check-input:checked +  .form-check-label{background-image: url("../images/check_on2.png")}

@media only screen and (min-device-width : 1280px) and (max-device-width : 1600px){
.container{max-width:93%!important}
.max_1200 {max-width: 90%;}


div#contenuArticle .container {max-width: 1300px!important;}
.bloc_administrable_i .container {max-width: 100%!important;}
.content_commun_logging .container {max-width: 100%!important;}
.text-opti .container {max-width: 100%!important;}
.bloc_tendance  >.container{max-width:1100px}
.newsletter_wrapper .container.p-0 {max-width: 1300px!important;}
.contenuArticle .container.p-0 {max-width: 1300px!important;}
.headerFixed li.navItem{padding-left: 10px; padding-right: 10px}

.headerFixed .navigation-site >.container {max-width:100%!important}
}

/********contaznct***********/
.contact-footer-instit{background:#ccc url('../images/bg_contact.jpg') no-repeat center right  ;background-attachment: fixed;background-size: cover;padding: 30px 15px; text-align:center;}
.contact-container{max-width: 1088px;width:100%; margin:auto}
.step-contact{max-width: 1148px;padding:30px; margin:auto}
.contact_form {margin: 25px auto;}
.step-contact .form-group{margin-bottom: 27px}
.form-perso-content .contact_form .form-control, .step-contact .form-control{padding-left: 35px;}
		.contact_form i::before ,.step-contact i::before {left: 8px;top:50%; transform: translate(0, -50%); -webkit-transform: translate(0, -50%); -ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);-spec-transform: translate(0, -50%);z-index: 9;}
		.form-perso-content .form-control,.step-contact .form-control, .form-perso-content .ui.form .form-control {border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;-spec-border-radius: 0;font-size: 14px;color:#000000; background-color: #E6E6E6; height: 40px; border:none;}
	.form-perso-content .form-control:focus,.step-contact .form-control:focus{box-shadow:none;-webkit-box-shadow:none;-ms-box-shadow:none;-o-box-shadow:none;-spec-box-shadow:none;border-color: #45A89E;}
	.form-perso-content .ui.form .form-control  {box-shadow:none;-webkit-box-shadow:none;-ms-box-shadow:none;-o-box-shadow:none;-spec-box-shadow:none;border-color: #45A89E;background-color: #E6E6E6!important;}
		.form-perso-content  .contact_form textarea.form-control, .step-contact textarea.form-control {min-height: 190px;padding-top: 13px}
		.form-perso-content.response-form .custom-file-label{background-color: #fff!important;}
		.form-perso-content.response-form textarea.form-control{padding-left: 35px;}
		.contact_form .form-group>label, .no-label-form .form-group>label,.no-label-form #app_comment_customer >div>label {font-size: 0;height: 0;margin-bottom: 0;line-height: 0;display: block;}
		.with-label-form .form-group>label{color: #000; font:16px "Sofia Pro";}
			#app_comment_customer{margin-bottom: 27px;}
		.contact_form .icon-users::before,.step-contact .icon-users::before  {content: url('../images/ico_user.png');position: absolute;}
		.contact_form .icon-mail::before ,.step-contact .icon-mail::before  {content: url('../images/ico_email.png');position: absolute;}
		.contact_form .icon-phone::before,.step-contact .icon-phone::before  {content: url('../images/ico_tel.png');position: absolute;left:9px; margin-top: 1px;}
		.contact_form .icon-home::before, .step-contact .icon-home::before {content: url('../images/ico_home.png');position: absolute;}
		.contact_form .icon-map::before, .step-contact .icon-map::before {content: url('../images/ico_map.png');position: absolute;}
		.contact_form .icon-pencil::before,.step-contact .icon-pencil::before  {content: url('../images/ico_pencil.png');position: absolute;top: 13px; transform: none; -webkit-transform: none; -ms-transform: none; -o-transform: none;-spec-transform: none;}
		.form-perso-content .custom-file,.step-contact .custom-file  {height: 40px;border:none}
		.custom-file.perso-file-custom {height: auto;display: block;}
		.perso-file-custom .MultiFile-list{padding-top: 10px;}
			.perso-file-custom  div.MultiFile-label {display: inline-block;vertical-align: middle;}

		.form-perso-content  .custom-file-label,.step-contact  .custom-file-label {background: #E6E6E6 url('../images/ico_down.png') no-repeat center left 10px;height: 40px;padding-left: 40px; font:14px "Sofia Pro"; padding-top: 12px;border:0; border-radius:0; -webkit-border-radius:0; -ms-border-radius:0;-o-border-radius:0;-spec-border-radius: 0;cursor:pointer; color: #000;    padding-right: 190px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;}
		.form-perso-content .custom-file-label::after, .step-contact .custom-file-label::after {background: #809982;height: 100%;content: "Parcourir";min-width: 190px;text-align: center;cursor: pointer;font:14px "Sofia Pro"; line-height: 30px;border:0; border-radius:0; -webkit-border-radius:0; -ms-border-radius:0;-o-border-radius:0;-spec-border-radius: 0;color: #fff}
		html[lang="en"] .form-perso-content .custom-file-label::after {content: "Browse"}
		.file-has-label .custom-file-label {top: 25px;margin-bottom: 0;}
		.custom-file-input{cursor: pointer;height: 100%;min-height: 40px;}
		.perso-file-custom input[type="file"]{cursor: pointer;height: 100%;min-height: 40px;width: 100%; opacity: 0;position: relative;z-index: 2;}
			.step-contact .label-ok-file.custom-file-label{color: #809982; font:14px "Sofia Pro";padding-left: 15px;background-image: none;}
			.step-contact  .label-ok-file.custom-file-label::before{content:url('../images/ok_check.png');position: absolute;left: -25px; top: 50%; transform: translate(0,-50%);-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);-spec-transform: translate(0, -50%);margin-top: 2px;}

			.step-contact  .parent-ok-file>label::after{content:url('../images/ok_check.png');display: inline-block;margin-left: 5px}

.custom-file-btn .custom-file-input {position: relative;z-index: 2;width: 100%;height: calc(1.7em + 0.75rem + 2px);margin: 0;opacity: 0;height: 100%;cursor: pointer;}
.custom-file-btn .custom-file-label {background: #809982;text-align: center;font: 13px "Sofia Pro-Bold";padding: 12px 30px;color:#ffffff;border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;-spec-border-radius: 0;border: none;cursor: pointer;    display: block;position: relative;height: auto;max-width: 460px;margin: auto; }

div#sylius_contact_attachment_list, .MultiFile-list {display: flex;display: -ms-flex;margin-top: 10px;flex-wrap: wrap;-ms-flex-wrap: wrap;}

div.MultiFile-label {   padding: 2px 15px 6px 15px;background: #45A89E;margin-right: 15px;color: #fff;margin-bottom: 5px;}

a.MultiFile-remove {font-weight: bold;vertical-align: middle;display: inline-block;font-size: 13px;color: #fff}
div.MultiFile-label >* {line-height: normal;vertical-align: middle;}

.caract-panier-content .form-perso-content .contact_form textarea.form-control{min-height: 150px}
.project-bit-list:last-child{margin-bottom: 0!important;}

/*******ui form*********/
.form-perso-content .ui.form .field>.selection.dropdown {height: 40px;background: #E6E6E6;border: none;border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;-spec-border-radius: 0;}

.form-perso-content .ui.form .field>.selection.dropdown .default.text {color: #000;padding-top: 5px;}
.form-perso-content .ui.form .field>.selection.dropdown>.dropdown.icon:before{content: url('../images/f_select.png');margin-top: 2px;display: block;}
.form-perso-content .ui.form .field>.selection.dropdown.loading>.dropdown.icon:before{display: none;}
.form-perso-content .help-text {font-size: 11px;font-style: italic;color: #999;}
.form-perso-content  .ui.toggle.checkbox input:checked ~ .box:before,.form-perso-content  .ui.toggle.checkbox input:checked ~ label:before,.form-perso-content  .ui.toggle.checkbox input:focus:checked ~ .box:before,.form-perso-content  .ui.toggle.checkbox input:focus:checked ~ label:before {background-color: #809982!important;}
/*=================================
			CLIENT
===================================*/
.commun-logging.contenu-other-page .table-perso .card-header.font-size-cardheader {font-size: 13px;}
.bg-transparent-account{background: rgba(255,255,255,0.8);}
.content-client {/*background: #E3E8D5 url('../images/bg_transparent_p.png') no-repeat center top;*/background: rgba(255,255,255,1);
	background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(226,231,212,1) 80%, rgba(226,231,212,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(80%, rgba(226,231,212,1)), color-stop(100%, rgba(226,231,212,1)));
	background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(226,231,212,1) 80%, rgba(226,231,212,1) 100%);
	background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(226,231,212,1) 80%, rgba(226,231,212,1) 100%);
	background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(226,231,212,1) 80%, rgba(226,231,212,1) 100%);
	background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(226,231,212,1) 80%, rgba(226,231,212,1) 100%);
	background-size: 100% ;position: relative;padding-bottom: 60px;font-size: 14px;/*background-blend-mode: multiply;*/}

.login-list {padding-left: 0;    margin-top: 23px;}
	.login-list-item {background: url("../images/ok_check.png") no-repeat center left;list-style: none;padding: 8px 0 8px 30px;color: #393A3D;}
	.login-list-item strong{color: #45A89E;}
.body-sylius_shop_login .alert {font-size: 1.2rem;}

	.titre-compte-c{text-align: left;padding-bottom: 15px;font: 36px "Sofia Pro";color: #809982}
.padding-lr-40{padding-left: 40px; padding-right: 40px}
.max-width-left-dash{width: 280px;}
	.ssttire-client{font:18px "Sofia Pro";}
	.list-item-bit {background: #fff;}
	.list-item-bit.menu-lock {display: none;}
		.list-item-bit a {color: #000;padding:22px 0;position: relative;font:16px "Sofia Pro";border-bottom: 1px solid #809982!important; border-radius: 0!important;-webkit-border-radius: 0!important;-ms-border-radius: 0!important;-o-border-radius: 0!important;-spec-border-radius: 0!important;	background:none!important;}
		.lien-loggout {display: block;max-width: 140px;text-align: center;background: #809982;margin: 25px auto 15px auto;color: #fff;padding: 11px;text-transform: uppercase;font: 14px "Sofia Pro";}
		.lien-loggout:hover,.lien-loggout:focus {background-color: #9AAF9B; color: #fff}
		.bg-vert2.btn-link:hover, .bg-vert2.btn-link:focus{background:#9AAF9B!important}
		.list-item-bit a:hover{color:#45A89E;}
		.sylius_shop_account_dashboard .lien-home,.sylius_shop_account_profile_update .menu-home + .menu-user a.lien-user,.sylius_shop_account_change_password  .menu-home + .menu-user a.lien-user,.sylius_shop_account_change_password a.lien-lock,.sylius_shop_account_address_book_index .lien-book,.sylius_shop_account_address_book_create .lien-book,.sylius_shop_account_address_book_update  .lien-book, .sylius_shop_account_order_index a.lien-cart, .sylius_shop_account_order_show a.lien-cart , .app_shop_account_project_show  a.lien-project, .app_shop_account_project_index a.lien-project, .arobases_sylius_customer_support_plugin_shop_customer_support_create .menu-cart + .menu-user a.lien-user, .arobases_sylius_customer_support_plugin_shop_account_customer_support_index  .menu-cart + .menu-user a.lien-user, .arobases_sylius_customer_support_plugin_shop_customer_support_update  .menu-cart + .menu-user a.lien-user, .arobases_sylius_professional_customer_shop_account_dashboard a.lien-star ,.app_shop_account_lead_index a.lien-project-lead,.app_shop_account_lead_show  a.lien-project-lead {color:#45A89E;}
		.list-item-bit a:focus{color:#45A89E;}
		.form-perso-content select.form-control{border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;-spec-border-radius: 0;background: #E6E6E6 url("../images/f_select.png") no-repeat center right 10px;}

		.form-perso-content .dropdown.address-book-select{border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;-spec-border-radius: 0;background: #E6E6E6 url("../images/f_select.png") no-repeat center right 10px;padding: 11px 35px 11px 10px;}
		.form-perso-content .dropdown.address-book-select .dropdown-item.active, .form-perso-content .dropdown.address-book-select .dropdown-item:active,.form-perso-content .dropdown.address-book-select .dropdown-item:focus {background:#45A89E}
		.form-perso-content .dropdown.address-book-select .dropdown-item{white-space: normal;}
		section.big-wrapper>.bloc-show-administrable {margin-top: 0;}

		.content-adress.bg-perso-point.pt-0.pt-md-3.pb-3.pb-md-5{    background: rgba(242,242,242,0.7);}
.h-200{height: 200px; overflow:hidden;}
.list-group-client .list-group-item{border-top:1px solid #809982!important; padding-top: 40px; padding-bottom: 40px}
.list-group-client .list-group-item.padding-30-all{padding: 30px}
.mb-40{margin-bottom: 40px}
.list-group-client .list-group-item:last-child{border-bottom: 1px solid #809982!important}
	.btn-link.perso-download-icon span {padding-left: 20px;background: url(../images/ico_down_w.png) no-repeat center left;}
	.border-site-vert{border:1px solid #809982}
		/*******ADRESSE*****/
/*		.list-group-item.list-group-item-action.border-0 {display: none;}*/
		.list-adress .col-lg-6:nth-child(2n) .card.rounded-0.border-0, .list-adress .col-lg-6:last-child .card.rounded-0.border-0 {border: none!important;}
		.sylius_shop_account_dashboard + .col-12 .bg-white.menu-right.p-lg-4 {padding: 0!important;}
		a.mr-1.btn.btn-link.btn-br.rounded-0.border-0.text-uppercase.text-white {line-height: 36px;}
		.list-adress .btn.btn-outline.btn-link {padding-top: 22px;padding-bottom: 22px;}
		.list-adress .btn.btn-icone {width: 45px;min-width: 45px;margin-left:5px;line-height: 21px;}
		.bg-red{background-color:#D10000!important;}
	.retour-cl{width: 30px;height: 30px;background: url("../images/retours.svg") no-repeat center;position: absolute;left:0;    top: 50%;transform: translate(0, -50%);-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);-spec-transform: translate(0, -50%);}

    .account-project-documents-title {display: flex; justify-content: space-between; align-items: center;}
    .account-project-documents-title > a {padding: 15px; }

	/*********SAV*********/
	#gridFiltersAccordion + div .action-table-perso form {display:inline-block;vertical-align: middle;}
	#gridFiltersAccordion + div .action-table-perso form button{min-width: 80px!important;font:12px "Sofia Pro-Medium"; padding: 1rem 10px!important;border-radius: 0 !important;-webkit-border-radius: 0 !important;-ms-border-radius: 0 !important;background: #809982;color:#ffffff;text-transform: uppercase;margin-right: 0;}
	/**********HISTORIQUE COMMANDE*********/

    .content-bottom-order .table-perso .card-header{background-color: #000!important;color: #ffffff;}
    .content-bottom-order .table-perso .card-header th{font-size: 12px;white-space: pre;}
      .content-bottom-order  .sylius-product-name{    font: 25px "Sofia Pro";text-transform: none;color: #000;	border-bottom:1px solid #809982!important;padding-bottom: 15px; }
      .content-bottom-order .table-perso table {font-size: 12px;}
      .content-bottom-order .table-perso  .total-cart-summ{font-size: 18px;}
       .content-bottom-order .table-perso .total-label{font-size: 15px;}
	    .content-bottom-order .table-perso .price-normal-panier{font-size: 15px}
		.content-bottom-order .box-shadow-persos{box-shadow: none!important;}
		.content-bottom-order  .row.panier-list {margin-left: -5px!important;margin-right: -5px!important;}
			.content-bottom-order  .row.panier-list>.col-xl.col-md-7{padding-left: 5px!important;padding-right: 5px!important;}
			.content-bottom-order  .row.panier-list>.col-xl-auto.col-md-5{padding-left: 5px!important;padding-right: 5px!important;}
			.content-bottom-order	.box-shadow-persos.padding-30-panier{padding: 5px 0 ;}
			.content-bottom-order .paiement-panier, .content-bottom-order .btn-panier-cmd {display: none!important;}


     .content-bottom-order #order-invoices  h3#shipping-state{text-transform: uppercase;     text-align: left;padding-bottom: 15px;font: 36px "Sofia Pro";color: #809982;border-bottom: none;}
	 div#order-invoices {padding: 0;border: 0;}
     .content-bottom-order #order-invoices table th {font: 14px "Sofia Pro";background-color: #000;color:#fff; vertical-align: middle;border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;}
     .content-bottom-order #order-invoices table{font-size: 14px; color:#000000;background: #ffffff; border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;-spec-border-radius: 0;}
	 .content-bottom-order #order-invoices table td{vertical-align: middle;border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;-spec-border-radius: 0;}
	 .table-histo-account  td.sylius-table-column-total {font-family: 'Sofia Pro';color: #45A89E;}
     #order-invoices table a {color: #45A89E;background: none;}
     #order-invoices table a:hover{text-decoration: underline!important;}
		.table-histo-account {font-size: 12px;}
		.table-histo-account td{vertical-align: middle;}
		.table-histo-account th a {color: #fff;}
		.content-bottom-order .panier-bottom .checkout-content-shippay .col-12.col-sm-6 {flex: 0 0 100%;-ms-flex: 0 0 100%;max-width: 100%;}
		.content-bottom-order .panier-bottom .checkout-content-adresse .row> .col-12.col-sm-6 {flex: 0 0 100%;-ms-flex: 0 0 100%;max-width: 100%;margin-top:15px;margin-bottom: 0!important;}
		table.table.table-perso.table-histo-account .card-header th {padding-left: 5px;padding-right: 5px;background:#000000; color:#ffffff}
		.content-bottom-order .resume-table-panier td{padding-left: 5px!important;}
		.content-bottom-order .resume-table-panier td.text-right.pr-0{padding-right: 5px!important;}
		.test table#sylius-checkout-subtotal td {font-size: 15px;}
		.content-bottom-order table#sylius-checkout-subtotal{margin-bottom: 0;}
		.content-account .d-none-histo{display: none!important;}
		.content-bottom-order table#sylius-checkout-subtotal th {border-top: none;}

		.content-bottom-order #sylius-payments .card.bg-light.h-100{height: auto!important;}
/******PASSWORD*******/
.icon-eye-content {position: absolute;right: 5px;top: 50%;width: 42px;height: 25px;}
input#showPassword {position: absolute;opacity: 0;width: 100%;height: 100%;top: 0;left: 0;cursor: pointer;}
.position-relative.pswd-bloc .form-control {padding-right: 45px!important;}
.icon-eye-content span {display: block;width: 100%;height: 100%;background:#E6E6E6 url("../images/icon_oeil.png") no-repeat center top;background-size: 60%;}
.icon-eye-content input#showPassword:checked + span{background-position: center bottom;}
.font-small {font-size: 12px;}
.badge-success .icon-cancel:before {content: '\e914';}
.text-success{color: #45A89E!important}
.badge-success{background-color: #45A89E!important}

/********SAV filters*********/
.filters-account .form-row.col-lg-4.col-12 >.col-perso-css {max-width: 100%;flex: 0 0 100%;-ms-flex: 0 0 100%;-webkit-flex: 0 0 100%;}

.filters-account .form-group .form-inline >.form-control {max-width: 50%;border-radius: 0;-ms-border-radius: 0;-webkit-border-radius: 0;-o-border-radius: 0;}
.filters-account .form-control{border-radius: 0!important;-ms-border-radius: 0!important;-webkit-border-radius: 0!important;-o-border-radius: 0!important;}
#gridFiltersAccordion .ui.styled.fluid.accordion.rounded-0 ,#gridFiltersAccordion  .ui.styled.accordion .content{border-color: #809982!important;}
#gridFiltersAccordion .accordion .title{color: #809982;}
#gridFiltersAccordion legend.col-form-label {padding: 0;margin-bottom: 0.5rem;line-height: 1.5;}
.content-bottom-order  .row.panier-list>.col-xl.col-md-7{flex: 0 0 100%;-ms-flex: 0 0 100%;-webkit-flex: 0 0 100%;max-width: 100%;}
	.content-bottom-order  .row.panier-list> .col-xl-auto.col-md-5{flex: 0 0 100%;-ms-flex: 0 0 100%;-webkit-flex: 0 0 100%;max-width: 100%;}
@media only screen and (min-device-width : 992px){
	.content-bottom-order  .row.panier-list>.col-xl.col-md-7{flex: 0 0 66.66667%;-ms-flex: 0 0 66.66667%;-webkit-flex: 0 0 66.66667%;max-width: 66.66667%;}
	.content-bottom-order  .row.panier-list> .col-xl-auto.col-md-5{flex: 0 0 33.33333%;-ms-flex: 0 0 33.33333%;-webkit-flex: 0 0 33.33333%;max-width: 33.33333%;}
	.content-bottom-order  .row.panier-list .resume-table-panier{width: auto;}
}
@media only screen and (min-device-width : 1200px){

	.page-sylius_shop_account_dashboard .col-12.col-md-8.border-left-account {padding: 0;}
	.content-account.position-relative {border-bottom: 60px solid #fff;}
	.btn-account-perso .btn-link {font-size: 14px;padding-top: 14px;padding-bottom: 14px;}
}
@media only screen and (min-device-width : 1300px) and (max-device-width : 1500px){
	.container{max-width:93%!important}
	.bloc-slider .container.p-0 {max-width: 100%!important; }
	.max_1200 {max-width: 93%;}
	div#contenuArticle .container {max-width: 1300px!important;}
	.content_commun_logging .container {max-width: 100%!important;}
	.contenuArticle .container.p-0 {max-width: 1300px!important;}
	.content-article-b{ max-width: 70%;}
}
.object-fit-vid{overflow: hidden;}
.object-fit-vid video,.object-fit-vid iframe {width: 100%!important; background: #000;height:100%; min-height: 200px;}
h2.ui.center.aligned.icon.header {text-align: center;}
.sortable a:hover {color: rgba(255,255,255,0.5);}
body:not(.modal-open) .modal-backdrop.show {opacity: 0!important;display: none!important;}

h2.ui.center.aligned.icon.header {text-align: center;}
.sortable a:hover {color: rgba(255,255,255,0.5);}
body:not(.modal-open) .modal-backdrop.show {opacity: 0!important;display: none!important;}

/************PAGE INSTIT STYLE*********/
#page-project .reseau-bloc.mt-xl-5.pt-4,#page-panier .reseau-bloc.mt-xl-5.pt-4, .body-sylius_shop_login  .reseau-bloc.mt-xl-5.pt-4,.body-sylius_shop_register  .reseau-bloc.mt-xl-5.pt-4 {margin-top: 0!important; padding-top:0!important;}
.font-size-cardheader {font: 22px "Sofia Pro";color: #809982;background: none;border-bottom: 1px solid #809982!important;padding: 15px 0;}
	.body-sylius_shop_login::after, .body-sylius_shop_register::after, .body-sylius_pro_shop_register::after {content:  url("../images/after_bgproject.png"); position: absolute;left: 0;bottom: 15%}
	.img-instit-top{max-height: 300px;overflow: hidden;}
.error-notfound {font: 100px "Sofia Pro-Medium";}


.perso-step2-config p>strong{display:inline-block;width: 25px;height:25px;text-align: center;background: #01B495;border-radius: 100%;padding-top: 4px;color: #01B495;text-shadow:1px 1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, -1px -1px 0 #fff, 1px 0px 0 #fff, 0px 1px 0 #fff, -1px 0px 0 #fff, 0px -1px 0 #fff;-webkit-text-shadow:1px 1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, -1px -1px 0 #fff, 1px 0px 0 #fff, 0px 1px 0 #fff, -1px 0px 0 #fff, 0px -1px 0 #fff;-ms-text-shadow:1px 1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, -1px -1px 0 #fff, 1px 0px 0 #fff, 0px 1px 0 #fff, -1px 0px 0 #fff, 0px -1px 0 #fff;margin-top: -4px;margin-right: 2px;font-size: 14px;}
/**********FAQ*****************/
.content-faq {max-width: 980px;margin: auto;}
.faq-header .bitbag-question{font: 16px "Sofia Pro";}
.faq-header.open h3{color: #809982;}
.faq-header::after {content: '\e8c2';display: block;position: absolute;right: 15px;top: 50%;font-family: "fontello";font-size: 17px;transform: translate(0,-50%);-webkit-transform : translate(0, -50%);-ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);-spec-transform: translate(0, -50%);font-weight: 700;}
.faq-header.open::after{color: #809982;content: '\e8c5';}


	div#accordion .card-header {cursor: pointer;}
		div#accordion .card.border-bottom {border-color: #809982!important;}
/************COOKIES*********/
#modalCookies2 .ch-cookie-consent__category label{border-radius: 0!important;-webkit-border-radius: 0!important;-ms-border-radius: 0!important;-o-border-radius: 0!important;-spec-border-radius: 0!important;margin-bottom: 0;}
#modalCookies2 .ch-cookie-consent__category-toggle input:checked+label,#modalCookies .ch-cookie-consent__category-toggle input+label:hover{background-color: #45A89E!important;}
#modalCookies2 .ch-cookie-consent h4 {font-size: 12px;}
#modalCookies2 .ch-cookie-consent__category{padding: 10px 0;align-items: center;-webkit-align-items: center;}
.ch-cookie-consent__category-group {margin-left: auto; margin-right: auto;max-width: 600px;}
#modalCookies2 a.ch-cookie-consent__read-more,.ch-cookie-consent__toggle-details span {text-decoration: underline;color: #fff ;margin-left:5px;font-weight: bold;cursor: pointer;}
.ch-cookie-consent__toggle-details span:hover{text-decoration: none;}
h3.ch-cookie-consent__title {color: #fff;text-align: center;font-size: 14px;}
#modalCookies2 {position: fixed;bottom: 0;width: 100%;padding:15px;background:rgba(19,45,52,0.94);z-index: 9999;color: #fff;text-align: center;display: none;}
.ch-cookie-consent__toggle-details-hide {display: none;}
.ch-cookie-consent__category-group {display: none;    position: fixed;width: 100%;max-width: 400px;padding: 15px;background: #fff;z-index: 99;top: 50%;left: 50%;transform: translate(-50%, -50%);-webkit-transform: translate(-50%, -50%);-ms-transform: translate(-50%, -50%);-o-transform: translate(-50%, -50%);-spec-transform: translate(-50%, -50%);z-index: 99;}
button#cookie_consent_use_all_cookies{    justify-content: center;-ms-justify-content: center;margin-top: 0;}
button.close-me {position: absolute;right: -14px;border: none;background:#000;border-radius: 100%;-webkit-border-radius: 100%;-ms-border-radius: 100%;-o-border-radius: 100%;-spec-border-radius: 100%;font-size: 20px;font-weight: bold;color: #fff;padding: 0;width: 30px;height: 30px;line-height: 5px;top: -11px;text-align: center;}
button#cookie_consent_use_only_functional_cookies {background: none;color: #FFF!important;min-width: auto;padding: 0;text-decoration: underline;margin-right: 10px; border: none;margin-top: 0; justify-content: center;-ms-justify-content: center; position: absolute; top: 0; right: 0;}
.ch-cookie-consent{background: none!important;}
body .loading-overlay {position: fixed;background: rgba(0, 0, 0, 0.1);}
.ch-cookie-consent .btn{background: #45A89E ;color:#fff!important; text-align:center;  width: auto;margin-top:0; border-radius: 0; -webkit-border-radius: 0; -ms-border-radius: 0;-o-border-radius: 0;-spec-border-radius: 0;padding: 5px 20px }
.loading-overlay-c {position: fixed;top: 0;left: 0;z-index: 9;display: none;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.5);}
.ch-cookie-consent__category-toggle input:checked+label, .ch-cookie-consent__category-toggle input+label:hover{background-color: #1a1a1a!important;}
.ch-cookie-consent__category:first-child {border-top: none;}
.close-cookies{background:#fff url("../images/close_popup.png") no-repeat center;font-size: 0;padding: 15px; position: absolute;right:0 ; top:0}
.ch-cookie-consent__form.d-inline-block.mt-0 {vertical-align: middle;}

.ch-cookie-consent__btn-wrapper {margin-bottom: 0!important;}
