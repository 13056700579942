@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro.woff2') format('woff2'),
        url('../fonts/SofiaPro.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Sofia Pro-Italic';
    src: url('../fonts/SofiaPro_Italic.woff2') format('woff2'),
        url('../fonts/SofiaPro_Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'Sofia Pro-Medium';
    src: url('../fonts/SofiaPro_Medium.woff2') format('woff2'),
        url('../fonts/SofiaPro_Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Sofia Pro-Light';
    src: url('../fonts/SofiaPro_Light.woff2') format('woff2'),
        url('../fonts/SofiaPro_Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Sofia Pro-Light-Italic';
    src: url('../fonts/SofiaPro_LightItalic.woff2') format('woff2'),
        url('../fonts/SofiaPro_LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'DKLemonYellowSun';
    src: url('../fonts/DKLemonYellowSun_Regular.woff2') format('woff2'),
        url('../fonts/DKLemonYellowSun_Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

